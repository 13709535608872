<div class="w-full max-w-auto">
  <div class="text-3xl font-bold tracking-tight leading-none" title="{{ 'create-signatures' | transloco }}"><!--Add Signers-->{{ "create-signatures" | transloco }}</div>

  <mat-button-toggle-group class="mt-6" [(ngModel)]="selectedOption" #toggleBtn="matButtonToggleGroup">
    <mat-button-toggle class="toggle-button" title="{{ 'upload-image' | transloco }}" value="Upload">{{ "upload-image" | transloco }}</mat-button-toggle>
    <mat-button-toggle class="toggle-button" title="{{ 'draw-signature' | transloco }}" value="Draw">{{ "draw-signature" | transloco }}</mat-button-toggle>
    <mat-button-toggle class="toggle-button" title="{{ 'text-signature' | transloco }}" value="Text">{{ "text-signature" | transloco }}</mat-button-toggle>
  </mat-button-toggle-group>

  <div class="w-full m-auto">
    <div *ngIf="selectedOption === 'Upload'">
      <div *ngIf="!uploaded">
        <div class="inside-card mt-7 p-6 border-2 rounded border-dashed border-gray-400 text-center bg-card">
          <button mat-stroked-button title="{{ 'browse' | transloco }}" (click)="uploader.click()" class="rounded">
            {{ "browse" | transloco }}
          </button>
          <input hidden type="file" #uploader (change)="uploadSignatureImage($event)" />
        </div>

        <div class="flex items-center justify-end mt-7">
          <button title="{{ 'close' | transloco }}" mat-stroked-button type="button" (click)="closeDialog()">
            {{ "close" | transloco }}
          </button>
        </div>
      </div>

      <div *ngIf="uploaded">
        <div class="inside-card mt-7 p-6 border-2 rounded border-dashed border-gray-400 text-center bg-card">
          <div class="flex items-center justify-center">
            <img *ngIf="signatureImage" class="w-50 h-50 object-contain" [src]="signatureImage" draggable="false" alt="SignatureImage" />
          </div>
        </div>
        <div class="flex items-center justify-between mt-7">
          <button title="{{ 'browse' | transloco }}" class="rounded" mat-stroked-button (click)="uploader.click()">
            {{ "browse" | transloco }}
          </button>
          <input hidden type="file" #uploader (change)="uploadSignatureImage($event)" />

          <div class="flex items-center space-x-4">
            <button class="ml-4" mat-stroked-button type="button" title="{{ 'close' | transloco }}" (click)="closeDialog()">
              {{ "close" | transloco }}
            </button>
            <button class="ml-4" mat-flat-button title="{{ 'save' | transloco }}" type="button" [color]="'primary'" (click)="saveUploadImage()">
              <span *ngIf="!spinner">{{ "save" | transloco }}</span>
              <mat-spinner *ngIf="spinner" [diameter]="22" class="inline align-middle"></mat-spinner>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div [hidden]="selectedOption !== 'Draw'">
      <div class="inside-card mt-7 border-2 rounded border-dashed border-gray-400 bg-card">
        <div class="container">
          <div class="row">
            <div class="col">
              <canvas title="{{ 'draw-signature-here' | transloco }}" #canvas width="500px" height="250px"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between mt-7">
        <button mat-stroked-button title="{{ 'clear' | transloco }}" type="button" (click)="clearSignPad()">
          {{ "clear" | transloco }}
        </button>

        <!-- <div> -->
        <div class="flex items-center space-x-4">
          <button mat-stroked-button title="{{ 'close' | transloco }}" type="button" (click)="closeDialog()">
            <span>{{ "close" | transloco }}</span>
          </button>

          <button mat-flat-button title="{{ 'save' | transloco }}" [color]="'primary'" [disabled]="isEmptyCanvas()" (click)="saveSignPad()">
            <span *ngIf="!spinner">{{ "save" | transloco }}</span>
            <mat-spinner *ngIf="spinner" [diameter]="22" class="inline align-middle"></mat-spinner>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="selectedOption === 'Text'" class="w-full m-auto">
      <div class="row">
        <div class="col">
          <div class="w-full">
            <p class="text-secondary mt-5" title="{{ 'type-your-signature-below' | transloco }}">
              {{ "type-your-signature-below" | transloco }}
            </p>
          </div>

          <mat-form-field class="fuse-mat-no-subscript w-full mt-2">
            <input [(ngModel)]="signatureText" title="{{ 'enter-here' | transloco }}" type="text" matInput placeholder="{{ 'enter-here' | transloco }}" />
          </mat-form-field>
        </div>
      </div>

      <div class="inside-text-card mt-5 border-2 rounded border-dashed border-gray-400 bg-card" title="{{ 'signature' | transloco }}">
        <div id="signature-container" class="generated-signature">
          {{ signatureText }}
        </div>
      </div>

      <div class="flex items-center justify-end mt-7">
        <button mat-stroked-button type="button" title="{{ 'close' | transloco }}" (click)="closeDialog()">{{ "close" | transloco }}</button>

        <button class="ml-4" mat-flat-button title="{{ 'save' | transloco }}" type="button" [color]="'primary'" [disabled]="!signatureText || signatureText.trim().length === 0" (click)="generateSignatureFromText()">
          <span *ngIf="!spinner">{{ "save" | transloco }}</span>
          <mat-spinner *ngIf="spinner" [diameter]="22" class="inline align-middle"></mat-spinner>
        </button>
      </div>
    </div>
  </div>

  <!-- <div class="w-full m-auto ">

        <div *ngIf="showAlert" style="min-width: 10px;" class="mt-8 -mb-4">
            <fuse-alert [dismissible]="true" [dismissed]="dismiss" [appearance]="'fill'" [type]="alert.type">
                <strong>{{alert.message}}</strong>
            </fuse-alert>
        </div>

    </div> -->
</div>
