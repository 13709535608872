import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { appConfig } from '../config/app.config.dev';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {

  isDigitalSignatureAvailable: boolean = true;

  constructor(
    private _httpClient: HttpClient
  ) { }
  changeConfigurationType(): Observable<any> {
    return this._httpClient.get<boolean>(appConfig.basePath + "v0/configurations/getConfigurations")
    .pipe(
      tap((response: boolean) => {
        this.isDigitalSignatureAvailable = response;
      }),
      catchError((error) => {
        console.error('Error fetching configuration:', error);
        return new Observable<boolean>();
      })
    );
  }

  // enableOrDisableDigitalSignature(value: boolean): Observable<any> {
  //   let payload = { digitalSignatureEnabled : value };
  //   return this._httpClient.post<any>(appConfig.basePath + "v0/configurations/enableConfigurations", payload);

  //   // return of(this.isDigitalSignatureAvailable);
  // }

  enableOrDisableDigitalSignature(value: boolean): Observable<any> {
    let payload = { digitalSignatureEnabled: value };
    return this._httpClient.post<any>(appConfig.basePath + "v0/configurations/enableConfigurations", payload)
       .pipe(
          catchError(error => {
             console.error('API request failed:', error);
             throw error; // Rethrow the error after logging
          })
       );
 }
  updateConfigurationType(value: boolean): Observable<any> {
    let payload = { digitalSignatureEnabled : value };
    return this._httpClient.put<any>(appConfig.basePath + "v0/configurations/updateConfigurations", payload);

    // return of(this.isDigitalSignatureAvailable);
  }

  getConfigurationLocale(): Observable<any> {
   
    return this._httpClient.get<any>(appConfig.basePath + "v0/configurations/getLocale");
  }
  setConfigurationLocale(value: string): Observable<any> {
    let payload = { locale : value };
    return this._httpClient.post<any>(appConfig.basePath + "v0/configurations/setLocale", payload);
  }


}
