import { ChangeDetectorRef, Component, ElementRef, Inject, Injector, OnInit, Renderer2, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseAlertType } from "@fuse/components/alert";
import { TranslocoService } from "@ngneat/transloco";
import { User } from "app/core/administrationSettings/user.types";
import { DocumentService } from "app/core/document/document.service";
import { Docinfo } from "app/core/document/document.types";
import { SettingService } from "app/core/setting/setting.service";
import { UserSettings } from "app/core/setting/setting.type";
import { UserService } from "app/core/user/user.service";
import { SignatureType } from "app/core/user/user.types";
import { DocumentDialogService } from "app/modules/document/document-common/document-dialogs/document-dialog.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-signature-types-dialog",
  templateUrl: "./signature-types-dialog.component.html",
  styleUrls: ["./signature-types-dialog.component.scss"],
})
export class SignatureTypesDialogComponent implements OnInit {
  alert: { type: FuseAlertType; message: string } = {
    type: "success",
    message: "",
  };

  _documentDialogService: any;
  serverInputValue: string;

  // signatureStyles = ['Helvetica', 'Pacifico', 'Arial', 'Verdana'];
  // selectedSignatureStyle = this.signatureStyles[0];

  userSettings: UserSettings;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  documentOwner: any;
  documentId: string;
  ownerEmail: String;
  showWhoWantsYouToSign: boolean;
  certificate: any[];
  guestORNot: boolean;
  signatureValue: any;
  user: User;

  isOwner: boolean = true;

  public _docDetails: Docinfo = {
    createdAt: 0,
    fileName: "",
    id: 0,
    name: "",
    owner: "",
    ownerName: "",
    size: 0,
    status: "",
    type: "",
    videoSign: true,
    pages: "0",
    sequentialSign:false,
    selfSign: false
  };

  @ViewChild('radioGroup') radioGroup: ElementRef;

  scrollDown() {
    const groupElement = this.radioGroup.nativeElement;
    groupElement.scrollTop += 20; // Adjust the value based on your scroll requirements
  }

  scrollUp() {
    const groupElement = this.radioGroup.nativeElement;
    groupElement.scrollTop -= 20; // Adjust the value based on your scroll requirements
  }

  constructor(private _userService: UserService, private _activatedRoute: ActivatedRoute, private _documentService: DocumentService, private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog, private _settingService: SettingService, private _domSanitizer: DomSanitizer, private _injector: Injector, private _router: Router,private _transloclService: TranslocoService, private dialogRef: MatDialogRef<SignatureTypesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this._documentDialogService = _injector.get<DocumentDialogService>(DocumentDialogService);
    console.log(data);
    this.documentOwner = data.name;

    this.ownerEmail = data.ownerEmail;
    this.certificate = data.key;
    this.guestORNot = data.signatureTypeAddedOrNot;

    console.log(this.documentOwner);
  }

  ngOnInit(): void {

    this.documentId = atob(this._activatedRoute.snapshot.queryParamMap.get("pI"));

    console.log("signature type dialog: " + this.documentId);
    

    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = user;
      if (user.email === this.ownerEmail) {
        this.showWhoWantsYouToSign = false;
      } else { this.showWhoWantsYouToSign = true; }
    });

    this._documentService.getCurrentDocumentsDetails(this.documentId).subscribe(
      (responseData) => {
        this._docDetails = responseData;
        console.log(responseData);
        console.log(this._docDetails);

        this.isOwner = this._docDetails.owner === this.user.email ? true : false;

        // console.log("this is my document owner: " + this._docDetails.owner);

        // console.log("this is my user: " + this.user.email);
        
        
    
        // console.log("I am owner of this document");
        
        // console.log(this.isOwner);
        
      },
      () => {
        // console.log('Completed fetching data.');
      }
    );

    // this.isOwner = this._docDetails.owner === this.user.email ? true : false;

    // console.log("this is my document owner: " + this._docDetails.owner);

    // console.log("this is my user: " + this.user.email);
    
    

    // console.log("I am owner of this document");
    
    // console.log(this.isOwner);
     
  }
  public closeMe() {
    this.dialogRef.close();
  }

  getUserSettings() {
    // Subscribe to the setting service

    this._settingService.userSetting$.pipe(takeUntil(this._unsubscribeAll)).subscribe((userSettings: UserSettings) => {
      this.userSettings = userSettings;
    });
  }

  saveSignatureType() {
    let body;
    if (this.signatureValue == SignatureType.Token) {
      body = {
        signtype: this.signatureValue,
      };
    } else {
      body = {
        signtype: this.signatureValue,
        signValue: this.serverInputValue,
      };
    }
    this.dialogRef.close(body);
  }

  authorizeKey() {
    this.dialogRef.close(this.signatureValue);
  }

  closeDialog(value: boolean) {
    this.dialogRef.close(value);
    let redirectURL = "/documents";
    this._router.navigateByUrl(redirectURL);
  }
}
// function ViewChild(arg0: string): (target: SignatureTypesDialogComponent, propertyKey: "radioGroup") => void {
//   throw new Error("Function not implemented.");
// }

