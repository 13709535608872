import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { User } from "app/core/user/user.types";
import { appConfig } from "app/core/config/app.config.dev";
import { Router, RouterState, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  [x: string]: any;
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _router: Router) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  // get(): Observable<User> {
  //   //old api/common/user
  //   return this._httpClient.get<User>(appConfig.basePath + "user/me").pipe(
  //     tap((user) => {
  //       // console.log(this._router.url);

  //       //Check if user is guest
  //       // if (this._router.url != "") {
  //       //   console.log(user);

  //       //   if (user.status == "GUEST") {
  //       //     if (this._router.url.includes("documents/prepare")) {
  //       //       console.log("Accepted");
  //       //     } else {
  //       //       console.log("logout\n============");

  //       //       // localStorage.setItem(
  //       //       //   "accessToken",
  //       //       //   "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjQ4NDY0ODk2LCJleHAiOjE2NDg0ODY0OTZ9.201mCFEoEcnzPqWo5nlPF_T9ZgSntjiSX5LYXtd04Og"
  //       //       // );

  //       //       // Redirect to the sign-in page
  //       //       // window. location. reload();
  //       //       this._user.next(user);
  //       //       this._router.navigateByUrl("/documents");
  //       //     }
  //       //   }
  //       // }

  //       this._user.next(user);
  //     })
  //   );
  // }
  upgradeAccount(): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/organization/upgrade", true);
  }

  getOrganizationUser(): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/organization/info");
  }

  getOrganization(): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/organization/information");
  }

  sendInvitation(data: string): Observable<any> {
    let obj = {
      email: data,
    };
    return this._httpClient.post(appConfig.basePath + "v0/organization/user/invite", obj);
  }

  removeUserFromOrganization(id: number): Observable<any> {
    return this._httpClient.delete(appConfig.basePath + "v0/organization/user/remove/" + id);
  }

  addOrganization(base64: string, name: string): Observable<any> {
    let obj = {
      name: name,
      logo: base64,
    };
    // console.log(obj);

    return this._httpClient.post(appConfig.basePath + "v0/organization/add", obj);
  }

  updateOrganization(base64: string, name: string): Observable<any> {
    let obj = {
      name: name,
      logo: base64,
    };
    return this._httpClient.put(appConfig.basePath + "v0/organization/update", obj);
  }

  /**
   * @param formData
   */
  updateSignatureImage(fileToUpload: File, signatureType: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("signatureType", signatureType);
    return this._httpClient.post(appConfig.basePath + "v0/user/upload/signature", formData);
    //old api/Settings/SignatureImage
    //new v0/user/upload/signature
  }

  getSignatureImage(): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/user/getSignatureImage");
    //old api/Settings/SignatureImage
    //new v0/user/upload/signature
  }

  getSignerSignatureImage(email: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/user/signatureImage/" + email);
    //old api/Settings/SignatureImage
    //new v0/user/upload/signature
  }

  /**
   * Update the user
   *
   * @param user
   */
  // update(user: User): Observable<any> {
  //   return this._httpClient.patch<User>("api/common/user", { user }).pipe(
  //     map((response) => {
  //       this._user.next(response);
  //     })
  //   );
  // }

  getIPAddress(): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/user/ip");
  }

  getIp(): Observable<any> {
    // http://ipv4.myexternalip.com/json
    // https://geolocation-db.com/json/
    return this._httpClient.get<any>("https://www.cloudflare.com/cdn-cgi/trace");
  }
}
