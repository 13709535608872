import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { FuseNavigationService, FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import { Navigation } from "app/core/navigation/navigation.types";
import { NavigationService } from "app/core/navigation/navigation.service";
import { AccountType, Role, User } from "app/core/user/user.types";
import { UserService } from "app/core/user/user.service";
import { FilterByStatusService } from "app/modules/document/filter-by-status.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PopupComponent } from "app/modules/popup/popup.component";
import { OrganizationService } from "app/core/administrationSettings/organization.service";
import { DocumentDialogService } from "app/modules/document/document-common/document-dialogs/document-dialog.service";
import { FuseConfirmationDialogComponent } from "@fuse/services/confirmation/dialog/dialog.component";

@Component({
  selector: "classy-layout",
  templateUrl: "./classy.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  user: User;
  adminOrNot: boolean = false;
  changeInUserComponent: boolean;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  circleColor: string;
  //color =['#EB7181','#468547','#FFD558','#3670B2','#00FFFF','#F5F5DC','#FFF0F5','#E0FFFF','#FFB6C1','#778899','#AFEEEE']
  color = ["#F5F5DC", "#FFF0F5", "#E0FFFF", "#778899", "#AFEEEE"];
  private _documentDialogService: DocumentDialogService;

  /**
   * Constructor
   */
  constructor(private _organizationService: OrganizationService, private _injector: Injector, private _activatedRoute: ActivatedRoute, private _router: Router, private _navigationService: NavigationService, private _userService: UserService, private _fuseMediaWatcherService: FuseMediaWatcherService, private _filterByStatus: FilterByStatusService, private _fuseNavigationService: FuseNavigationService, private cdr: ChangeDetectorRef, private matDialog: MatDialog) {
    this._documentDialogService = _injector.get<DocumentDialogService>(DocumentDialogService);
  }
  openDialog() {
    const dialogRef = this._documentDialogService.enterPrizeDialog("upgrade-your-account", "upgrade-your-account-to-a-business-account");
    // const dialogConfig = new MatDialogConfig();
    // const dialogRef = this.matDialog.open(FuseConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      console.log("Received value from PopupComponent:", result);
      if (result == "confirmed") {
        this._organizationService.upgradeAccount().subscribe(
          (response) => {
            this.adminOrNot = false;
            this.changeInUserComponent = true;
            // console.log(response.status);
          },
          (error) => {
            // console.log(error.error.errorDescription);
          }
        );
      }

      // You can handle the returned value here
    });
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.user = this.getUser();
    //the below check is show upgrade button is a user is admin or simple user.
    // if (this.user.roles[0].name === Role.Admin) {
    //   this.adminOrNot = false;
    // }
    if (this.user.accountType == AccountType.user && this.user.organization == null) {
      this.adminOrNot = true;
    }
    // Subscribe to navigation data
    this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
      this.navigation = navigation;
    });

    // Subscribe to the user service
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = user;
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
      // Check if the screen is small
      this.isScreenSmall = !matchingAliases.includes("md");
    });

    const randomIndex = Math.floor(Math.random() * Math.floor(this.color.length));
    this.circleColor = this.color[randomIndex];
    this.getInitials();
  }

  // refreshComponent(){

  //     this._filterByStatus.getFirstCount().subscribe(
  //         data => {
  //           // This block will execute when the Observable emits data
  //           console.log("update Count")
  //           console.log(data);
  //         },
  //         error => {
  //           // This block will execute if there's an error during the request
  //           console.error('There was an error:', error);
  //         },
  //         () => {
  //           // This block will execute once the Observable completes (i.e., no more data is expected)
  //           console.log('Request completed');
  //         }
  //       );

  // }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  getInitials() {
    // var operatorRole = this.user.name;
    var name = this.user.name;
    if (name.includes(" ")) {
      const fullName = name.split(" ");
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    } else {
      const fullName = name.substring(0, 2);
      return fullName.toUpperCase();
    }
  }

  getUser() {
    // console.log("checking user");
    let user2: User;
    // Subscribe to the user service
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      user2 = user;
    });
    return user2;
  }
}
