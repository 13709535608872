import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, Injector } from "@angular/core";
import { UserService } from "app/core/user/user.service";
import { FuseAlertType } from "@fuse/components/alert";
import { registerLocaleData } from "@angular/common";
import { SettingService } from "app/core/setting/setting.service";
import { UserSettings } from "app/core/setting/setting.type";
import { takeUntil } from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import SignaturePad from "signature_pad";
import html2canvas from "html2canvas";
import { DocumentDialogService } from "app/modules/document/document-common/document-dialogs/document-dialog.service";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-manage-signature-dialog",
  templateUrl: "./manage-signature-dialog.component.html",
  styleUrls: ["./manage-signature-dialog.component.scss"],
})
export class ManageSignatureDialogComponent implements OnInit {
  alert: { type: FuseAlertType; message: string } = {
    type: "success",
    message: "",
  };

  showAlert: boolean = false;
  dismiss: boolean = false;
  spinner: boolean = false;

  selectedFile: File;

  signatureType: String;

  savingInProgress = false;

  _documentDialogService: any;

  uploaded: boolean = false;
  signatureImage: SafeResourceUrl;
  getSignatureImage: SafeResourceUrl;
  getImage: boolean = false;
  isUploadSectionSelected: boolean = true;

  drawnSignatureImage: SafeResourceUrl;
  textSignatureImage: SafeResourceUrl;

  @ViewChild("canvas", { static: true }) canvas: ElementRef;

  signpad: SignaturePad;
  signImage: any;
  signatureText: any;

  selectedOption = "Upload";

  // signatureStyles = ['Helvetica', 'Pacifico', 'Arial', 'Verdana'];
  // selectedSignatureStyle = this.signatureStyles[0];

  userSettings: UserSettings;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private _userService: UserService, private changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog, private _settingService: SettingService, private _domSanitizer: DomSanitizer, private _injector: Injector, private _transloclService: TranslocoService, private dialogRef: MatDialogRef<ManageSignatureDialogComponent>) {
    this._documentDialogService = _injector.get<DocumentDialogService>(DocumentDialogService);
  }

  ngOnInit(): void {
    this.getUserSettings();
    this.setImageOnNgInit(this.userSettings);

    this.signpad = new SignaturePad(this.canvas.nativeElement);

    this._userService.getSignatureImage().subscribe((response) => {
      // console.log(response);
      this.getSignatureImage = this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + response.imageContent);
      this.changeDetectorRef.detectChanges();
      this.uploaded = false;
    });

    this.changeDetectorRef.detectChanges();
    this.showAlert = false;
  }

  getUserSettings() {
    // Subscribe to the setting service

    this._settingService.userSetting$.pipe(takeUntil(this._unsubscribeAll)).subscribe((userSettings: UserSettings) => {
      this.userSettings = userSettings;
    });
  }

  startSignPadDrawing(enent: MouseEvent) {
    // console.log(enent);
  }

  movedFinger(event: Event) {}

  async saveSignPad() {
    // this.spinner=true;
    const base64ImageData = this.signpad.toDataURL();
    this.signImage = base64ImageData;
    this.drawnSignatureImage = base64ImageData;

    this.textSignatureImage = "";
    this.isUploadSectionSelected = false;

    // Convert base64 to Blob
    const byteString = atob(base64ImageData.split(",")[1]);
    const mimeString = base64ImageData.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });

    // Create File object from Blob
    const file = new File([blob], "signature.png", { type: blob.type });

    // console.log(file);
    // console.log()

    await this.sendFileToServer(file);
    // this.dialogRef.close();
  }

  clearSignPad() {
    this.signpad.clear();
  }

  createSignature() {
    this._documentDialogService.uploadSignatureDialog("success", this._transloclService.translate("An email has been sent to all the signers to sign the document."));
  }

  async generateSignatureFromText() {
    this.spinner = true;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = 500;
    // canvas.height = 500;
    canvas.height = 250;
    // context.font = '80px  Pacifico';

    const text = this.signatureText;

    let fontSize = 100;
    do {
      fontSize--;
      context.font = `${fontSize}px SamanthaSignature`;
    } while (context.measureText(text).width > canvas.width || fontSize * 1.2 > canvas.height);

    const textMetrics = context.measureText(text);

    // Calculate the center position
    const centerX = (canvas.width - textMetrics.width) / 2;
    const centerY = canvas.height / 2 + (textMetrics.actualBoundingBoxAscent - textMetrics.actualBoundingBoxDescent) / 2;

    context.fillText(text, centerX, centerY);

    const base64ImageData = canvas.toDataURL();
    // this.signatureImage = base64ImageData;
    this.textSignatureImage = base64ImageData;
    // console.log(this.textSignatureImage);

    this.drawnSignatureImage = "";
    this.isUploadSectionSelected = false;

    const byteString = atob(base64ImageData.split(",")[1]);
    const mimeString = base64ImageData.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });

    // Create File object from Blob
    const file = new File([blob], "signature.png", { type: blob.type });

    // console.log(file);

    await this.sendFileToServer(file);
    // this.dialogRef.close();

    canvas.remove();
  }

  // getSignatureFromText() {
  //   const container = document.getElementById('signature-container');

  //   html2canvas(container).then((canvas) => {
  //     const base64ImageData = canvas.toDataURL();
  //     this.textSignatureImage = base64ImageData;

  //     console.log(this.textSignatureImage);

  //     this.drawnSignatureImage = '';
  //     this.isUploadSectionSelected = false;

  //     const byteString = atob(base64ImageData.split(',')[1]);
  //     const mimeString = base64ImageData.split(',')[0].split(':')[1].split(';')[0];
  //     const arrayBuffer = new ArrayBuffer(byteString.length);
  //     const uintArray = new Uint8Array(arrayBuffer);

  //     for (let i = 0; i < byteString.length; i++) {
  //       uintArray[i] = byteString.charCodeAt(i);
  //     }

  //     const blob = new Blob([arrayBuffer], { type: mimeString });

  //     // Create File object from Blob
  //     const file = new File([blob], 'signature.png', { type: blob.type });

  //     console.log(file);

  //     this.sendFileToServer(file);
  //     this.dialogRef.close();
  //   });
  // }

  isEmptyCanvas(): boolean {
    const context = this.canvas.nativeElement.getContext("2d");
    const pixelBuffer = new Uint32Array(context.getImageData(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height).data.buffer);
    return !pixelBuffer.some((color) => color !== 0);
  }

  setImageOnNgInit(userSettings: UserSettings) {
    if (userSettings.status == "success") {
      this.getSignatureImage = this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + userSettings.imageContent);
      this.getImage = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  uploadSignatureImage($event): void {
    let files = $event.target.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      // console.log(this.selectedFile = files[0]);

      this.uploaded = true;
      // console.log(files[0]);

      this.signatureImage = this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.selectedFile));
    }
  }

  private async sendFileToServer(file: any): Promise<any> {
    // console.log("uploaded file");
    this.spinner = true;
    this._userService.updateSignatureImage(file, this.selectedOption).subscribe(
      async (res) => {
        // console.log(res);
        if (res.status == "failure" || res.result == "error" || res.result == "failure") {
          this.spinner = false;
          this.alert.message = "Upload Failded!";
          this.alert.type = "error";
          this.showAlert = true;
          setTimeout(() => {
            this.ngOnInit();
          }, 2000);
        } else if (res.status == "success" || res.result == "success") {
          this.spinner = false;
          // this.dialog.closeAll();
          this.alert.type = "success";
          this.alert.message = "Signature uploaded successfully!";
          this.showAlert = true;
          this.uploaded = true;

          await this._userService
            .getSignatureImage()
            .toPromise()
            .then(async (response) => {
              // console.log(response);
              // this.uploaded = true;
              // this.signatureImage = null;
              this.signatureImage = this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + response.imageContent);
              this.changeDetectorRef.detectChanges();
              this.dialogRef.close('confirm');
              return await Promise.resolve(response.imageContent);
            });
        }
      },
      (res) => {
        // console.log(res);
        if (res.error.result == "error") {
          // this.alert.message = "Upload Failded!";
          // this.alert.type = "error";
          // this.showAlert = true;
          // setTimeout(() => {
          // console.log("showing error");
          this.alert.message = "Upload Failded!";
          this.alert.type = "error";
          this.showAlert = true;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.uploaded = true;
            //this.signatureImage = reader.result;
            this.changeDetectorRef.detectChanges();
            // console.log("successfully returned from server");
            this.uploaded = false;
            setTimeout(() => {
              this.ngOnInit();
            }, 2000);
          };

          // }, 2000);
          setTimeout(() => {
            this.changeDetectorRef.detectChanges();
            this.ngOnInit();
          }, 2000);
        }
      }
    );
  }

  saveUploadImage() {
    if (this.selectedFile) {
      this.spinner = true;
      this.savingInProgress = true;
      // console.log(this.selectedOption)
      this._userService.updateSignatureImage(this.selectedFile, this.selectedOption).subscribe(
        (res) => {
          if (res.status == "success" || res.result == "success") {
            this.spinner = false;
            this.alert.type = "success";
            this.alert.message = "Signature uploaded successfully!";
            this.showAlert = true;
            // this.uploaded = true;

            this._userService.getSignatureImage().subscribe((response) => {
              this.signatureImage = this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + response.imageContent);
              // console.log(this.signatureImage);

              this.uploaded = true;
              this.changeDetectorRef.detectChanges();
              this.dialogRef.close('confirm');
            });
          }
        },
        (error) => {
          this.spinner = false;
          this.alert.message = "Upload Failed!";
          this.alert.type = "error";
          this.showAlert = true;
          // this.uploaded = false;
          this.dialogRef.close();
        }
      );
    }
    // this.changeDetectorRef.detectChanges();
  }

  closeDialog() {
    this.dialogRef.close('close');
  }
}
