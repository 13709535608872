<!-- <div  *ngIf="getImage" class="-m-4 grid justify-items-end">
  <div class="">
    <button class=" w-10 h-10 " (click)="close()" mat-icon-button>
      <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>
</div> -->
<div class="w-full max-w-auto">
  <div class="w-full m-auto">
    <div class="text-3xl font-bold tracking-tight leading-none mb-5" title="{{ 'signature-appearance' | transloco }}">
      <!--Add Signers-->{{ "signature-appearance" | transloco }}
    </div>
    <div *ngIf="getImage" class="w-full m-auto" title="{{ 'signature-image' | transloco }}">
      <img class="inside-card p-6 border-2 rounded border-dashed border-gray-400 bg-card" [src]="getSignatureImage"
        draggable="false" alt="SignatureImage" />
    </div>
    <div *ngIf="!getImage" class="flex justify-center mt-5">
      <div class="py-10 spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>

  <div *ngIf="getImage" class="flex items-center justify-between mt-5">
    <div></div>
    <button mat-stroked-button title="{{ 'close' | transloco }}" type="button" (click)="close()">
      <span>{{ "close" | transloco }}</span>
    </button>
  </div>

</div>