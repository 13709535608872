import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';


declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  // constructor(private _router: Router) {
  //   this._router.events.pipe(
  //     filter(event => event instanceof NavigationEnd)
  //   ).subscribe((e: NavigationEnd) => {
  //     gtag('js', new Date());
  //     gtag('config', localEnvironment.googleAnalytics);
  //   });
  // }

  // init() {
  //   const script = document.createElement('script');
  //   script.src = `https://www.googletagmanager.com/gtag/js?id=${localEnvironment.googleAnalytics}`;
  //   script.async = true;
  //   document.getElementsByTagName('head')[0].appendChild(script);

  //   const gtagEl = document.createElement('script');
  //   const gtagBody = document.createTextNode(`
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(){dataLayer.push(arguments);}
  //   `);
  //   gtagEl.appendChild(gtagBody);
  //   document.body.appendChild(gtagEl);
  // }

  private googleAnalyticsId: string;
  private performanceCookiesEnabled: boolean = true;

  constructor(private _router: Router, private http: HttpClient) { }

  init() {

    this.http.get('assets/' + environment.analyticsFilename).subscribe(
      (config: any) => {
        this.googleAnalyticsId = config.googleAnalyticsId;

        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsId}`;
        script.async = true;
        document.getElementsByTagName('head')[0].appendChild(script);

        const gtagEl = document.createElement('script');
        const gtagBody = document.createTextNode(`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
        `);
        gtagEl.appendChild(gtagBody);
        document.body.appendChild(gtagEl);

        this._router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe((e: NavigationEnd) => {
          console.log(this.performanceCookiesEnabled);

          if (this.performanceCookiesEnabled) {
            gtag('js', new Date());
            gtag('config', this.googleAnalyticsId);
          }
        });
      },
      (error) => {
        console.error('Error fetching analytics config:', error);
      }
    );
  }

  updatePerformanceCookiesStatus(enabled: boolean) {
    this.performanceCookiesEnabled = enabled;
  }

  getPerformanceCookiesStatus(): boolean {
    return this.performanceCookiesEnabled;
  }

}
