import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthUtils } from "app/core/auth/auth.utils";
import { UserService } from "app/core/user/user.service";
import { appConfig } from "app/core/config/app.config.dev";
import { User } from "../user/user.types";

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  user: User;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem("accessToken", token);
  }

  get accessToken(): string {
    return localStorage.getItem("accessToken") ?? "";
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  debugger;

  /**
   * Sends email on Confirm Account
   *
   * @param email
   */
  resetConfirmAccount(email: string): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/user/sendConfirmAccountResetLink", email);
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    let userObj = { usernameOrEmail: email, password: "123" };
    return this._httpClient.post(appConfig.basePath + "v0/user/sendResetLink", userObj);
    //just email
    //old 'api/auth/forgot-password'
    //new 'v0/user/sendResetLink'
  }

  /**
   * Reset password
   *
   * @param password
   */
  /*
   *   old uri
   *   api/auth/reset-password
   *   new uri
   *   URI v0/user/sendResetLink
   *
   * // old password: string
   */
  resetPassword(changeRequest: { uuid: string; newPassword: any }): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/user/resetPassword", changeRequest);
  }

  authViaGoogle(code: string): Observable<any> {
    // console.log("in Method authViaGoogle");

    if (this._authenticated) {
      return throwError("User is already logged in.");
    }

    return this._httpClient.get(appConfig.basePath + "api/user/login?code=" + code).pipe(
      switchMap((response: any) => {
        if (response.accessToken != undefined) {
          // console.log(Date.now() + ": " + response.expiry);

          var tokenExpiry = Date.now() + parseInt(response.expiry, 10);
          // console.log(tokenExpiry);
          sessionStorage.setItem("tokenExpiry", tokenExpiry.toString());

          // Store the access token in the local storage
          this.accessToken = response.accessToken;
          // Set the authenticated flag to true
          this._authenticated = true;
          //let user: User = {
          //     id: response.data.Id,
          //      name: response.data.name,
          //     email: response.data.emailAddress
          //   };

          this._userService.getSignatureImage().subscribe((image) => {
            // console.log("am i getting response?");

            if (image.status == "success") {
              if (image.imageContent != "" || image.imageContent != null || image.imageContent != undefined) {
                let signatureImageContent = image.imageContent;
                let imgElementSrc = "data:image/png;base64," + signatureImageContent;

                localStorage.setItem("signature", imgElementSrc);

                let user: User = {
                  id: response.id,
                  name: response.name,
                  email: "credentials.usernameOrEmail",
                  username: response.username,
                  signature: imgElementSrc,
                  mfa: response.mfa,
                };
                // console.log("is signature set?");
                // console.log(user);

                // Store the user on the user service
                this._userService.user = user;
                localStorage.setItem("directory", user.username);
                localStorage.setItem("email", "credentials.usernameOrEmail");

                // this.changeDetectorRef.detectChanges();
              }
            } else {
              let user: User = {
                id: response.id,
                name: response.name,
                email: "credentials.usernameOrEmail",
                username: response.username,
                signature: "imgElementSrc",
                mfa: response.mfa,
              };
              // console.log("is signature set?");
              // console.log(user);

              // Store the user on the user service
              this._userService.user = user;
              localStorage.setItem("directory", user.username);
              localStorage.setItem("email", "credentials.usernameOrEmail");
            }
          });
        }

        // Return a new observable with the response
        return of(response);
      })
    );
  }
  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { usernameOrEmail: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError("User is already logged in.");
    }

    return this._httpClient.post(appConfig.basePath + "v0/auth/signin", credentials).pipe(
      switchMap((response: any) => {
        if (response.accessToken != undefined) {
          // console.log(Date.now() + ": " + response.expiry);

          var tokenExpiry = Date.now() + parseInt(response.expiry, 10);
          // console.log(tokenExpiry);
          sessionStorage.setItem("tokenExpiry", tokenExpiry.toString());

          // Store the access token in the local storage
          this.accessToken = response.accessToken;
          // Set the authenticated flag to true
          this._authenticated = true;
          //let user: User = {
          //     id: response.data.Id,
          //      name: response.data.name,
          //     email: response.data.emailAddress
          //   };

          this._userService.getSignatureImage().subscribe((image) => {
            // console.log("am i getting response?");

            if (image.status == "success") {
              if (image.imageContent != "" || image.imageContent != null || image.imageContent != undefined) {
                let signatureImageContent = image.imageContent;
                let imgElementSrc = "data:image/png;base64," + signatureImageContent;

                localStorage.setItem("signature", imgElementSrc);

                let user: User = {
                  id: response.id,
                  name: response.name,
                  email: credentials.usernameOrEmail,
                  username: response.username,
                  signature: imgElementSrc,
                  mfa: response.mfa,
                };
                // console.log("is signature set?");
                // console.log(user);

                // Store the user on the user service
                this._userService.user = user;
                localStorage.setItem("directory", user.username);
                localStorage.setItem("email", credentials.usernameOrEmail);

                // this.changeDetectorRef.detectChanges();
              }
            } else {
              let user: User = {
                id: response.id,
                name: response.name,
                email: credentials.usernameOrEmail,
                username: response.username,
                signature: "imgElementSrc",
                mfa: response.mfa,
              };
              // console.log("is signature set?");
              // console.log(user);

              // Store the user on the user service
              this._userService.user = user;
              localStorage.setItem("directory", user.username);
              localStorage.setItem("email", credentials.usernameOrEmail);
            }
          });
        }

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  _unsubscribeAll(_unsubscribeAll: any): import("rxjs").OperatorFunction<User, unknown> {
    throw new Error("Method not implemented.");
  }

  checkByUrlAndToken(url: any): Observable<any> {
    return this._httpClient.get<User>(appConfig.basePath + "v0/user/me").pipe(
      catchError(() =>
        // Return false
        of(false)
      ),
      switchMap((user: User) => {

        const userRole = user.roles && user.roles.length > 0 ? user.roles[0].name : null;

        localStorage.setItem('userRole', userRole);

        return this._httpClient
          .post("api/auth/refresh-access-token", {
            accessToken: this.accessToken,
          })
          .pipe(
            catchError(() =>
              // Return false
              of(false)
            ),
            switchMap((response: any) => {
              // console.log("what is reponse in");
              // console.log(response);

              // this is necessary for refresh access token
              this._authenticated = true;

              // console.log(of(true));
              // console.log("what is ");

              //Check if user is guest
              if (url != "") {
                // console.log(user);

                if (user.status == "GUEST") {
                  if (url.includes("documents/prepare") || url.includes("feedback")) {
                    // console.log("Accepted");
                    // console.log(of(true));

                    return of(true);
                  } else {
                    // console.log("logout\n============");
                    this._authenticated = false;

                    return of(false);
                  }
                } else {
                  return of(true);
                }
              }
            })
          );
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .post("api/auth/refresh-access-token", {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Store the access token in the local storage
          //this.accessToken = response.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userRole");

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { FullName: string; EmailAddress: string; Password: string }): Observable<any> {
    //return this._httpClient.post('api/auth/sign-up', user);
    return this._httpClient.post(appConfig.basePath + "v0/auth/signup", user);
  }

  /**
   * Feedback
   *
   * @param feedback
   */
  feedback(feedback: { docId: number; stars: number }): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/serviceProvider/feedback", feedback);
  }

  validateCode(requestData: { username: string; code: string }): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v1/code/validate/key", requestData).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  /**
   * @param
   */

  verifyAccount(uuid: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/auth/verifyAccount/" + uuid);
  }

  /**
   * check feedback exist
   *
   * @param docId
   */
  checkFeedback(docId: number): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/checkFeedback/" + docId);
  }

  /**
   *
   * @param confirmRequest
   * @returns
   */

  // verifyAccount(confirmRequest): Observable<any> {
  //   return this._httpClient.post(
  //     appConfig.basePath + "api/Account/Verify",
  //     confirmRequest
  //   );
  // }

  sendVerifyEmail(email: string): Observable<any> {
    var data = JSON.stringify(email);
    var headers = { "Content-Type": "Application/JSON" };
    return this._httpClient.post(appConfig.basePath + "api/Account/Verify/Email", data, { headers });
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post("api/auth/unlock-session", credentials);
  }

  /**
   * Check the authentication status
   */
  check(redirectURL?: string): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return this.checkByUrlAndToken(redirectURL);
      // return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // console.log("what is check by url");

    // console.log(this.checkByUrlAndToken(redirectURL)._isScalar);

    // if (!this.checkByUrl(redirectURL)._isScalar) {
    //   return of(false);
    // }

    return this.checkByUrlAndToken(redirectURL);

    // // // If the access token exists and it didn't expire, sign in using it
    // return this.signInUsingToken();
  }
}
function takeUntil(_unsubscribeAll: any): import("rxjs").OperatorFunction<User, unknown> {
  throw new Error("Function not implemented.");
}
