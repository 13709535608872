<div class="w-full max-w-auto container">
    <div class="text-3xl font-bold tracking-tight leading-none" title="{{ 'save-template-details' | transloco }}">
        Manage Consent Preferences
    </div>

    <ng-container
        *ngFor="let cookieType of ['Strictly Necessary Cookies', 'Functional Cookies', 'Performance Cookies', 'Targeting Cookies']">
        <div class="flex justify-between text-xl font-bold mt-8 cursor-pointer" (click)="toggleAccordion(cookieType)">

            <span> {{isAccordionOpen(cookieType) ? '-' : '+'}} </span>
            <span class="ml-4"> {{ cookieType }} </span>

            <ng-container *ngIf="cookieType === 'Strictly Necessary Cookies'">
                <span class="text-md text-primary ml-auto">
                    Always Active
                </span>
            </ng-container>

            <ng-container *ngIf="cookieType === 'Functional Cookies'">
                <mat-slide-toggle class="ml-auto" [color]="'primary'" (click)="$event.stopPropagation()" checked="true">
                </mat-slide-toggle>
            </ng-container>

            <ng-container *ngIf="cookieType === 'Performance Cookies'">
                <mat-slide-toggle class="ml-auto" [color]="'primary'" (click)="togglePerformanceCookies($event)"
                    checked="analyticsService.performanceCookiesEnabled">
                </mat-slide-toggle>
            </ng-container>

            <ng-container *ngIf="cookieType === 'Targeting Cookies'">
                <mat-slide-toggle class="ml-auto" [color]="'primary'" (click)="$event.stopPropagation()" checked="true">
                </mat-slide-toggle>
            </ng-container>

        </div>

        <div *ngIf="isAccordionOpen(cookieType)" class="mt-2 ml-4">
            <ng-container *ngIf="cookieType === 'Strictly Necessary Cookies'">
                <p>
                    These cookies are necessary for the website to function and cannot be switched off in our systems.
                    They are usually only set in response to actions made by you which amount to a request for services,
                    such as setting your privacy preferences, logging in or filling in forms.
                    You can set your browser to block or alert you about these cookies, but some parts of the site will
                    not then work.
                    These cookies do not store any personally identifiable information.
                </p>
            </ng-container>

            <ng-container *ngIf="cookieType === 'Functional Cookies'">
                <p>
                    These cookies enable the website to provide enhanced functionality and personalisation.
                    They may be set by us or by third party providers whose services we have added to our pages.
                    If you do not allow these cookies then some or all of these services may not function properly.
                </p>
            </ng-container>

            <ng-container *ngIf="cookieType === 'Performance Cookies'">
                <p>
                    These cookies allow us to count visits and traffic sources so we can measure and improve the
                    performance of our site.
                    They help us to know which pages are the most and least popular and see how visitors move around the
                    site.
                    All information these cookies collect is aggregated and therefore anonymous.
                    If you do not allow these cookies we will not know when you have visited our site, and will not be
                    able to monitor its performance.
                </p>
            </ng-container>

            <ng-container *ngIf="cookieType === 'Targeting Cookies'">
                <p>
                    These cookies may be set through our site by our advertising partners.
                    They may be used by those companies to build a profile of your interests and show you relevant
                    adverts on other sites.
                    They do not store directly personal information, but are based on uniquely identifying your browser
                    and internet device.
                    If you do not allow these cookies, you will experience less targeted advertising.
                </p>
            </ng-container>
        </div>

    </ng-container>

    <div class="flex items-center justify-end space-x-4 mt-4">
        <button class="ml-4" mat-stroked-button type="button" title="{{ 'cancel' | transloco }}"
            (click)="closeDialog(false)">
            {{ 'reject-all' | transloco }}
        </button>
        <button class="ml-4" mat-flat-button title="{{ 'save' | transloco }}" type="button" [color]="'primary'"
            (click)="saveCookiePreferences()">
            <span *ngIf="!spinner"> {{ 'confirm-my-choices' | transloco }} </span>
            <mat-spinner *ngIf="spinner" [diameter]="22" class="inline align-middle"></mat-spinner>
        </button>
    </div>
</div>