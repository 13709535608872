import { Component, ElementRef, Injector, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import { TranslocoService } from '@ngneat/transloco';
import { SettingService } from 'app/core/setting/setting.service';
import { UserService } from 'app/core/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from 'app/core/document/document.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomSnackBarService } from 'app/modules/document/document-common/snackbars/snackbar.service';
import { TemplateAlreadyExistsComponent } from 'app/modules/document/document-common/template-already-exists/template-already-exists.component';
import { TemplateDialogsComponent } from 'app/modules/document/document-common/template-dialogs/template-dialogs.component';
import { AnalyticsService } from 'app/analytics.service';
import { CookieService } from 'app/core/cookie/cookie.service';

@Component({
  selector: 'app-manage-cookies-dialog',
  templateUrl: './manage-cookies-dialog.component.html',
  styleUrls: ['./manage-cookies-dialog.component.scss']
})
export class ManageCookiesDialogComponent implements OnInit {


  alert: { type: FuseAlertType; message: string } = {
    type: "success",
    message: "",
  };

  spinner: boolean;

  _customSnackbarService: any;

  accordionState: { [key: string]: boolean } = {};

  @ViewChild("radioGroup") radioGroup: ElementRef;
  constructor(private _analyticService: AnalyticsService, private cookieService: CookieService, private _injector: Injector, private _formBuilder: FormBuilder, private renderer: Renderer2, public dialog: MatDialog, private _settingService: SettingService, private _transloclService: TranslocoService, private dialogRef: MatDialogRef<TemplateDialogsComponent>) {
    this._customSnackbarService = _injector.get<CustomSnackBarService>(CustomSnackBarService);
  }

  ngOnInit(): void {

  }

  toggleAccordion(cookieType: string): void {
    this.accordionState[cookieType] = !this.accordionState[cookieType];
  }

  isAccordionOpen(cookieType: string): boolean {
    return this.accordionState[cookieType];
  }

  togglePerformanceCookies(event: Event) {
    event.stopPropagation();

    const newStatus = !this._analyticService.getPerformanceCookiesStatus();

    this._analyticService.updatePerformanceCookiesStatus(newStatus);

    this.cookieService.setCookie('performanceCookiesEnabled', newStatus.toString(), 365);


  }

  saveCookiePreferences() {
    for (const cookieType of ['Strictly Necessary Cookies', 'Functional Cookies', 'Performance Cookies', 'Targeting Cookies']) {
      const toggleValue = this.getToggleValue(cookieType);

      if (toggleValue !== undefined) {

        this.cookieService.setCookie(`${cookieType.replace(/ /g, '')}Enabled`, toggleValue.toString(), 365);

      }

    }

    this.cookieService.setCookie('isRejectedCookie', 'false', 7);


    this.closeDialogAfterAccepct(true);

  }


  private getToggleValue(cookieType: string): boolean {

    if (cookieType === 'Performance Cookies') {
      return this._analyticService.getPerformanceCookiesStatus();
    } else if (cookieType === 'Functional Cookies') {
      return;
    } else {
      return;
    }
  }


  closeDialog(value: boolean) {
    this.cookieService.setCookie('isRejectedCookie', 'true', 7)

    this.dialogRef.close(value);
  }

  closeDialogAfterAccepct(value: boolean) {
    this.dialogRef.close(value);
  }

}
