<div class="w-full max-w-auto">
  <div class="text-3xl font-bold tracking-tight leading-none" title="{{ 'sign-with-digital-id' | transloco }}">
    <!--Add Signers-->{{ "sign-with-digital-id" | transloco }}</div>

  <!-- <label id="example-radio-group-label">Pick your favorite season</label> -->
  <div class="flex flex-col w-full m-auto py-8">
    <div class="flex-auto">
      <div class="pb-4">
        <p *ngIf="!isOwner" class="text-xl">
          <span class="py-2 font-bold">{{ documentOwner }} </span>{{
          "wants-you-to-sign-this-document-with-a-digital-Id." | transloco }}
        </p>
        <p *ngIf="guestORNot" class="text-xl">
          <span class="py-2">{{ "choose-where-your-digital-ids-are-stored" | transloco }}</span>
        </p>
        <p *ngIf="!guestORNot" class="text-xl">
          <span class="py-2">{{ "choose-a-digital-id-you-want-to-sign-with" | transloco }}</span>
        </p>
      </div>
      <div *ngIf="guestORNot">
        <mat-radio-group [(ngModel)]="signatureValue" [color]="'primary'">
          <mat-radio-button class="flex pb-4" value="REMOTE">
            {{ "sign-with-remote-signature" | transloco }}
          </mat-radio-button>
          <mat-radio-button class="flex pb-4" value="TOKEN">
            {{ "sign-with-usb-token" | transloco }}
          </mat-radio-button>
          <mat-radio-button class="flex pb-4" value="SERVER">
            <span class="sm:flex-auto">
              {{ "sign-with-serverside-digital-id" | transloco }}
            </span>
          </mat-radio-button>
        </mat-radio-group>
        <ng-container *ngIf="signatureValue === 'REMOTE'">
          <mat-form-field class="flex-auto w-full">
            <mat-label>{{ "enter-your-remote-id" | transloco }}</mat-label>
            <input matInput type="text" [(ngModel)]="serverInputValue" />
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="signatureValue === 'SERVER'">
          <mat-form-field class="flex-auto w-full">
            <mat-label>{{ "enter-your-key" | transloco }}</mat-label>
            <input matInput type="text" [(ngModel)]="serverInputValue" />
          </mat-form-field>
        </ng-container>
      </div>
      <!-- SERVER-SIDE -->
      <div *ngIf="!guestORNot" class="max-h-45 h-20 px-4 overflow-y-auto">
        <mat-radio-group [(ngModel)]="signatureValue" *ngFor="let x of certificate" [color]="'primary'">
          <mat-radio-button title="{{user.name}}'s Key" class="flex py-2 items-center" value="{{ x }}">
            <mat-icon title="{{user.name}}'s Key" class="icon-size-4" [svgIcon]="'heroicons_solid:key'"
              matPrefix></mat-icon>
            <span class="sm:flex-auto">
              {{ x }}
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="flex items-center justify-end space-x-4">
    <!-- <button class="ml-4" mat-stroked-button type="button" title="{{ 'cancel' | transloco }}"
      (click)="closeDialog(false)">
      {{ "cancel" | transloco }}
    </button> -->
    <button *ngIf="guestORNot" class="ml-4" mat-flat-button title="{{ 'Ok' | transloco }}" type="button"
      [color]="'primary'" (click)="saveSignatureType()" [disabled]="!signatureValue">
      <span *ngIf="!spinner">{{ "Ok" | transloco }}</span>
      <mat-spinner *ngIf="spinner" [diameter]="22" class="inline align-middle"></mat-spinner>
    </button>
    <button *ngIf="!guestORNot" class="ml-4" mat-flat-button title="{{ 'authorize' | transloco }}" type="button"
      [color]="'primary'" (click)="authorizeKey()" [disabled]="!signatureValue">
      <span *ngIf="!spinner">{{ "authorize" | transloco }}</span>
      <mat-spinner *ngIf="spinner" [diameter]="22" class="inline align-middle"></mat-spinner>
    </button>
  </div>
</div>