<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="top-0 flex items-center w-full p-3 pl-6">
      <!-- Logo 8 -> 18 -->
      <div class="flex items-center content-top justify-center" title="vScrawl">
        <img class="w-5" src="assets/images/logo/vScrawl_v.svg" />
      </div>
      <!-- Components -->
      <div class="flex items-center content-top ml-auto" title="{{ user.name }}">
        <notifications></notifications>
        <user [showAvatar]="false" [isAdmin]="changeInUserComponent"></user>
      </div>
    </div>
    <!-- User -->
    <div class="flex flex-col mt-5 items-center w-full p-4">
      <div class="relative w-24 h-24">
        <!-- <img
                    class="w-full h-full rounded-full"
                    *ngIf="user.avatar"
                    [src]="user.avatar"
                    alt="User avatar">
                <mat-icon
                    class="icon-size-24"
                    *ngIf="!user.avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> -->
        <div class="flex items-center w-24 h-24 rounded-full" [ngStyle]="{ 'background-color': circleColor }">
          <span class="m-auto text-black text-5xl font-semibold">
            {{ getInitials() }}
          </span>
          <!-- <mat-icon
                        class="icon-size-24"
                        [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> -->
        </div>
      </div>
      <div class="flex flex-col items-center justify-center w-full mt-6" title="{{ user.name }} - {{ user.email }}">
        <div class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-center leading-normal font-medium">
          {{ user.name }}
        </div>
        <div class="w-full mt-0.5 whitespace-nowrap overflow-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
          {{ user.email }}
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationContentFooter>
    <div class="fixed bottom-10 left-0 flex flex-0 items-center justify-center h-16 pl-15 mb-8" *ngIf="adminOrNot">
      <button class="w-36 font-medium" mat-raised-button (click)="openDialog()" id="custom-button">{{ "upgrade" | transloco }}</button>
    </div>
    <div class="fixed bottom-0 left-0 flex flex-0 items-center justify-center h-16 pl-15 mb-4 opacity-30" title="vScrawl">
      <img class="max-w-36" src="assets/images/logo/vScrawl-new-logo-on-dark.svg" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')" title="{{ 'menu' | transloco }}">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2" title="{{ 'change-language' | transloco }}">
      <languages></languages>
      <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> 
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>-->
      <!--<button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>-->
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
