import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, Injector } from "@angular/core";
import { UserService } from "app/core/user/user.service";
import { FuseAlertType } from "@fuse/components/alert";
import { registerLocaleData } from "@angular/common";
import { SettingService } from "app/core/setting/setting.service";
import { UserSettings } from "app/core/setting/setting.type";
import { takeUntil, tap } from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import SignaturePad from "signature_pad";
import { DocumentDialogService } from "app/modules/document/document-common/document-dialogs/document-dialog.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SignatureType, User } from "app/core/user/user.types";
import { error } from "@angular/compiler/src/util";
import { CustomSnackBarService } from "app/modules/document/document-common/snackbars/snackbar.service";
import { ConfigurationsService } from "app/core/configurations/configurations.service";

@Component({
  selector: "signature-appearance",
  templateUrl: "./signature-appearance.component.html",
  styleUrls: ["./signature-appearance.component.scss"],
})
export class SignatureAppearanceComponent implements OnInit {

  getSignatureImage: SafeResourceUrl;
  getImage: boolean = false;

  constructor(private dialogRef: MatDialogRef<SignatureAppearanceComponent>, private _userService: UserService, private changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog, private _domSanitizer: DomSanitizer) { }

  async ngOnInit(): Promise<void> {

    await this.setImageOnNgInit();

  }

  close() {
    this.dialogRef.close()
  }

  async setImageOnNgInit() {
    await this._userService.getDigitalSignatureImage().subscribe((response) => {
      this.getSignatureImage = this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + response.imageContent);
      this.getImage = true;
      this.changeDetectorRef.detectChanges();
    })
  }


}
