import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Observer } from "rxjs";
import { appConfig } from "app/core/config/app.config.dev";
import { DeclineDocument, FieldType, PdfField, SignerDetails, SignerDocument, SignerDocumentDetails, TemplateDocument } from "app/core/document/document.types";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(private _httpClient: HttpClient) { }

  /**
   * @param
   */

  getDocumentSignerInfo(documentId: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/docSignerInfo/" + documentId);
  }

  /**
   * @param
   */

  getDocumentSignerVideoInfo(documentId: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/docSignerVideoInfo/" + documentId);
  }

  async getDocumentSignerVideoInfoJs(documentId: string) {
    let response = await fetch(appConfig.basePath + "v0/serviceProvider/docSignerVideoInfo/" + documentId, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    if (!response.ok) {
      return [];
    }
    let result = await response.json();
    return result;
  }
  /**
   * @param
   */

  getGuestToken(uuid: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/guestUuid/" + uuid);
  }

  /**
   * @param
   */

  getGuestUuid(docId: any, userId: any): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/waitForDocument/" + docId + "/" + userId);
  }

  /**
   * @param
   */

  checkIfSelfSign(documentId: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/isSelfSign/" + documentId);
  }

  /**
   * @param
   */

  setOnlineStatus(documentId: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/user/setOnline/" + documentId);
    //  (NEW)  just for the time being  v0/file/getPdf/{documentId}
    //  (OLD) api/documents/images?documentId
  }

  /**
   * @param
   */

  checkOnlineStatus(documentId: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/user/checkOnline/" + documentId);
    //  (NEW)  just for the time being  v0/file/getPdf/{documentId}
    //  (OLD) api/documents/images?documentId
  }

  /**
   * @param formData
   */
  uploadDocument(fileToUpload: File, isSelfSign: Boolean): Observable<any> {
    // console.log(isSelfSign)
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("isSelfSign", String(isSelfSign));
    return this._httpClient.post(appConfig.basePath + "v0/file/upload", formData);
    // OLD  api/documents/add
    // New  v0/file/upload
  }


  /**
   * @param formData
   */
  updateDocument(documentId: any, fileToUpload: File, documentDetails: any): Observable<any> {
    // console.log(isSelfSign)
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("documentDetails", JSON.stringify(documentDetails));
    return this._httpClient.put(appConfig.basePath + "v0/file/updateDocument/" + documentId, formData);
    // OLD  api/documents/add
    // New  v0/file/upload
  }


  addTemplate(template: TemplateDocument): Observable<any> {
    // console.log(isSelfSign)
    // const formData: FormData = new FormData();
    // formData.append("file", fileToUpload, fileToUpload.name);
    // formData.append("isSelfSign", String(isSelfSign));
    return this._httpClient.post(appConfig.basePath + "v0/template/add", template);
    // OLD  api/documents/add
    // New  v0/file/upload
  }

  /**
 * @param
 */

  useTemplate(templateId: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/template/use/" + templateId);
  }

  saveDocGeoDetails(docId: string, docGeoDetails: any): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/file/saveDocDetails/" + docId, docGeoDetails);
  }

  getDocOwner(docId: number): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/user/getDocOwnerDetails/" + docId);
  }

  /**
   * @param
   */

  getImages(documentId: string, start?: number, end?: number): Observable<any> {
    if (start != undefined && end != undefined) {
      return this._httpClient.get(appConfig.basePath + "v0/file/getPdfImages/" + documentId + "/" + start + "/" + end);
    } else {
      return this._httpClient.get(appConfig.basePath + "v0/file/getPdfImages/" + documentId);
    }

    //  (NEW)  just for the time being  v0/file/getPdf/{documentId}
    //  (OLD) api/documents/images?documentId
  }

  /**
   * @param
   */
  getDocuments(): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/user/list/dashboard");
    //old api/inboxitems/all
    //new v0/file/getDocumentAll
  }

  /**
   * @param
   */
  getTemplates(): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/template/get/list");
  }

  /**
   * @param
   */
  getCurrentDocumentsDetails(docId: any): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/file/getDocument/" + docId);
  }

  deleteInboxItem(docId: any): Observable<any> {
    return this._httpClient.delete(appConfig.basePath + "v0/file/Delete/" + docId);
    //"api/inboxitems/delete?id=" + id, id //Old
    // /v0/remove  //new
  }

  deleteTemplate(templateId: any): Observable<any> {
    return this._httpClient.delete(appConfig.basePath + "v0/template/delete/" + templateId);
  }

  getMeetinginfo(DocId: any): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/startMeeting/" + DocId);
    //"api/inboxitems/delete?id=" + id, id //Old
    // /v0/remove  //new
  }

  finish(documentId: any): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/renderSignBoxes/" + documentId);
    //Old 'api/documents/' + documentId + "/finish"
    //New /renderSignBoxes
    //New '/renderSignBoxes/'+documentId
  }

  selfSignFinish(documentId: any): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/selfSignEditorial/" + documentId);
  }

  async embedAnnotation(documentId: any) {
    try {
      let accessToken = localStorage.getItem("accessToken");
      let response = await fetch(appConfig.basePath + "v0/serviceProvider/setAnnotations/" + documentId, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Annotation added failed");
      }
      let result = await response.json();
      console.log(result);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  docEditorial(documentId: any): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/docEditorialProcess/" + documentId);
  }

  addSignature(documentId: any): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/setInvisibleSignature/" + documentId);
  }

  download(documentId: any, directory: any): any {
    return this._httpClient.get(appConfig.basePath + "v0/file/download/" + directory + "/" + documentId, { responseType: "blob" });
    //Old  "api/documents/" + documentId + "/download"
  }

  downloadTemplate(templateId: any): any {
    return this._httpClient.get(appConfig.basePath + "v0/template/download/" + templateId, { responseType: "blob" });
  }

  saveField(field: PdfField, documentId: string): Observable<any> {
    let requestObj = {
      packageId: documentId,
      pageNumber: field.pageNumber,
      fieldType: field.fieldType,
      fieldValue: field.fieldValue,
      posX: field.posX,
      posY: field.posY,
      width: field.width,
      height: field.height,
      email: field.email,
      signerName: field.signerName,
      fieldColor: field.fieldColor,
    };

    return this._httpClient.post(appConfig.basePath + "v0/serviceProvider/saveSignBoxes/" + documentId, requestObj);
    //new sp/renderSignBoxes
    //old 'api/documents/' + documentId + '/fields/add'
  }

  addVideoSign(docId: string, docVideoSign: boolean) {
    let requestObj = {
      videoSign: docVideoSign,
    };
    return this._httpClient.post(appConfig.basePath + "v0/file/docVideoSign/" + docId, requestObj);
  }

  removeField(fieldId: any, documentId: string): Observable<any> {
    // console.log(fieldId);
    return this._httpClient.delete(appConfig.basePath + "v0/serviceProvider/deleteSignBoxes/" + documentId + "/" + fieldId.toString());
  }

  updateField(field: PdfField, documentId: string): Observable<any> {
    let requestObj = {
      packageId: documentId,
      pageNumber: field.pageNumber,
      fieldType: field.fieldType,
      fieldValue: field.fieldValue,
      posX: field.posX,
      posY: field.posY,
      width: field.width,
      height: field.height,
      fieldId: field.id,
      fieldColor: field.fieldColor,
    };

    return this._httpClient.put(appConfig.basePath + "v0/serviceProvider/updateSignBoxes/" + documentId, requestObj);
    //new sp/renderSignBoxes
    //old 'api/documents/' + documentId + '/fields/add'
  }

  getFields(documentId: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/getSignBoxes/" + documentId);
    //  (NEW)  just for the time being  v0/file/getPdf/{documentId}
    //  (OLD) a'api/documents/' + documentId + '/fields/all
  }

  getEmbededFields(documentId: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/getEmbededSignBoxes/" + documentId);
  }

  addSigner(docId: string, signerDocDetails: SignerDocumentDetails): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/serviceProvider/addSigners/" + docId, signerDocDetails);
    //  (NEW)  just for the time being  v0/file/getPdf/{documentId}
    //  (OLD) a'api/documents/' + documentId + '/fields/all
  }

  deleteSigner(docId: any, signerId: any): Observable<any> {
    return this._httpClient.delete(appConfig.basePath + "v0/serviceProvider/deleteSigner/" + docId + "/" + signerId);
  }

  setSignTime(signerDetails: SignerDetails): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/serviceProvider/signerSignTime", signerDetails);
  }

  setViewTime(signerDetails: SignerDetails): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/serviceProvider/signerViewTime", signerDetails);
  }

  declineDocument(declineDocument: DeclineDocument): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/serviceProvider/declineDocument", declineDocument);
  }

  async voidDocument(docId: string) {
    return await this._httpClient.get(appConfig.basePath + "v0/serviceProvider/voidDocument/" + docId);
  }

  getDeclinedReason(docId: string): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/getDeclineReason/" + docId);
  }

  getSigner(docId: number): Observable<any> {
    return this._httpClient.get(appConfig.basePath + "v0/serviceProvider/docSignerVideoInfo/" + docId);
  }

  sendSignerMail(docId: string, signerDoc: SignerDocument): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/serviceProvider/sendSignerMail/" + docId + "/" + localStorage.getItem("lang"), signerDoc);
    //  (NEW)  just for the time being  v0/file/getPdf/{documentId}
    //  (OLD) a'api/documents/' + documentId + '/fields/all
  }

  resendSignerMail(docId: string, signerDoc: SignerDocument): Observable<any> {
    return this._httpClient.post(appConfig.basePath + "v0/serviceProvider/resendSignerMail/" + docId + "/" + localStorage.getItem("lang"), signerDoc, { responseType: 'text' });
  }
}
