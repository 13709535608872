import { RowOutlet } from "@angular/cdk/table";

export interface User {
  id: string;
  name: string;
  email: string;
  avatar?: string;
  status?: string;
  username?: string;
  signature?: any;
  roles?: any;
  mfa: Boolean;
  organization?: any;
  accountType?: AccountType;
  signatureType?: SignatureType;
  signatureIdentifier?: string;
}

export enum Role {
  User = "ROLE_USER",
  Admin = "ROLE_ADMIN",
  Guest = "Guest",
  Super_Admin = "Super_Admin",
}

export enum AccountType {
  Organization = "ORGANIZATION",
  user = "USER",
}
export enum SignatureType {
  Remote = "REMOTE",
  Token = "TOKEN",
  Server = "SERVER",
}
export enum SignatureTypes {
  DigitalSign = "DIGITALSIGN",
  E_Signature = "E-SIGNATURE",
}
