import { Injectable } from '@angular/core';
import { DocumentService } from 'app/core/document/document.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class FilterByStatusService {


private boolSubject = new BehaviorSubject<boolean>(false);
boolObservable = this.boolSubject.asObservable();



statusCounts:any;

  draftCount = 0;
  completeCount = 0;
  sentCount = 0;
  toBeSignedCount = 0;
  voidCount = 0;
  public documentsCount = 1;

  declare documents: any[];

  constructor( private _documentService: DocumentService,) { }

  setBoolValue(value: boolean): void {
    this.boolSubject.next(value);
 }

 getFirstCount(): Observable<{[status: string]: number}> {
    return this._documentService.getDocuments().pipe(
        map(response => {
            let statusCounts: {[status: string]: number} = {};
            response.forEach((value) => {
                if (statusCounts[value.status]) {
                    statusCounts[value.status]++;
                } else {
                    statusCounts[value.status] = 1;
                }
            });
            return statusCounts;
        })
    );
}




getStatusCount(): any {
  // console.log("Getting name...");
  return this.statusCounts;
}

// Setter function for the name property
setStatusCount(value:any) {
  // console.log("Setting name...");
  if (!value) {
      throw new Error("Name cannot be empty.");
  }
  this.statusCounts = value;
}



 // This subject will notify when the delete button is pressed
 private onDeleteSubject = new Subject<void>();
  
 // Observable that the Fuse navigation component can subscribe to
 onDelete$ = this.onDeleteSubject.asObservable();




 notifyDelete() {
    this.onDeleteSubject.next();
    // console.log("delete Detacted")
  }

}
