<div class="w-full max-w-3xl">
  <div *ngIf="!signatureAdded && uploaded" class="w-full m-auto">
    <div *ngIf="isUploadSectionSelected" class="w-full m-auto">
      <div>
        <p class="text-secondary" title="{{ 'your-saved-signatures' | transloco }}"><!-- Your saved signatures -->{{
          "your-saved-signatures" | transloco }}</p>
      </div>

      <div>
        <button class="mt-8" mat-flat-button [color]="'primary'" (click)="createSignature()"
          title="{{ 'create-signatures' | transloco }}">
          {{ "create-signatures" | transloco }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!signatureAdded && !uploaded">
    <div *ngIf="getImage && isUploadSectionSelected" class="w-full m-auto">
      <p class="text-secondary mt-4 mb-6" title="{{ 'your-saved-signatures' | transloco }}">
        {{ "your-saved-signatures" | transloco }}
      </p>
      <div *ngIf="getImage" class="flex items-center justify-start" title="{{ 'signature-image' | transloco }}">
        <img class="max-w-80 h-50 object-contain signature-box border-2 rounded border-dashed border-gray-400 p-3"
          [src]="getSignatureImage" draggable="false" alt="SignatureImage" />
      </div>

      <div *ngIf="getImage">
        <button class="mt-8" mat-flat-button [color]="'primary'" title="{{ 'update-signatures' | transloco }}"
          (click)="updateSignature()">
          {{ "update-signatures" | transloco }}
        </button>

        <br>
        <div title="{{ 'view-my-signature-appearance' | transloco }}" class="mt-5 cursor-pointer" (click)="showSignatureAppearance()">
          <a class="underline decoration-indigo-500 underline-offset-2">{{ "view-my-signature-appearance" | transloco }}
          </a>
        </div>

      </div>
    </div>
  </div>

  <!-- Divider -->
  <div *ngIf="isDigitalSignatureAvailable" class="my-10 border-t"></div>

  <div *ngIf="isDigitalSignatureAvailable" class="grid sm:grid-cols-4 gap-6 w-full mt-8">
    <div class="sm:col-span-4 flex items-center justify-between">
      <div class="flex-auto">
        <div class="leading-6 text-xl font-medium" title="{{ 'digital-id-preference' | transloco }}">{{
          "digital-id-preference" | transloco }}</div>
        <div class="text-md text-secondary"
          title="{{ 'choose-where-your-digital-Ids-are-stored-and-how-you-want-to-sign-the-document' | transloco }}">{{
          "choose-where-your-digital-Ids-are-stored-and-how-you-want-to-sign-the-document" | transloco }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="isDigitalSignatureAvailable" class="mt-4 flex flex-col">
    <!-- <mat-label class="mr-4">{{ "select-digital-signature" | transloco }}</mat-label> -->
    <mat-radio-group class="mt-4" aria-label="Select an option" color="primary" [(ngModel)]="selectedSignature"
      (change)="selectDigitalSignature()">
      <mat-radio-button class="flex font-normal" value="TOKEN">{{ "use-digital-IDs-from-usb-token" | transloco
        }}</mat-radio-button>
      <mat-radio-button class="flex font-normal" value="REMOTE">{{ "use-digital-IDs-from-remote-signing-server" |
        transloco }}</mat-radio-button>
      <mat-radio-button class="flex font-normal" value="SERVER">{{ "use-digital-IDs-from-a-server" | transloco
        }}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="isDigitalSignatureAvailable && identifier">
    <mat-form-field class="flex-auto w-full">
      <mat-label>{{ label | transloco }}</mat-label>
      <input matInput type="text" [(ngModel)]="identifierValue" />
    </mat-form-field>
    <div class="flex items-center justify-end">
      <button class="ml-4" mat-flat-button [color]="'primary'" [disabled]="!identifierValue"
        (click)="saveIdentifierValue()" title="{{ 'save' | transloco }}">
        <span *ngIf="!loader">{{ "save" | transloco }}</span>
        <mat-spinner [diameter]="22" *ngIf="loader"></mat-spinner>
      </button>
    </div>
  </div>



  <div class="w-full m-auto">
    <div *ngIf="showAlert" style="min-width: 10px" class="mt-8 -mb-4">
      <fuse-alert [dismissible]="true" [dismissed]="dismiss" [appearance]="'fill'" [type]="alert.type">
        <strong>{{ alert.message }}</strong>
      </fuse-alert>
    </div>
  </div>
</div>