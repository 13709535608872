import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, Injector } from "@angular/core";
import { UserService } from "app/core/user/user.service";
import { FuseAlertType } from "@fuse/components/alert";
import { registerLocaleData } from "@angular/common";
import { SettingService } from "app/core/setting/setting.service";
import { UserSettings } from "app/core/setting/setting.type";
import { takeUntil, tap } from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import SignaturePad from "signature_pad";
import { DocumentDialogService } from "app/modules/document/document-common/document-dialogs/document-dialog.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SignatureType, User } from "app/core/user/user.types";
import { error } from "@angular/compiler/src/util";
import { CustomSnackBarService } from "app/modules/document/document-common/snackbars/snackbar.service";
import { ConfigurationsService } from "app/core/configurations/configurations.service";

@Component({
  selector: "settings-signature",
  templateUrl: "./signature.component.html",
  styleUrls: ["./signature.component.scss"],
})
export class SettingsSignatureComponent implements OnInit {
  alert: { type: FuseAlertType; message: string } = {
    type: "success",
    message: "",
  };

  loader: boolean = false;

  showAlert: boolean = false;
  dismiss: boolean = false;
  selectedSignature: string;

  _documentDialogService: DocumentDialogService;

  uploaded: boolean = false;
  signatureAdded: boolean = false;
  signatureImage: SafeResourceUrl;
  getSignatureImage: SafeResourceUrl;
  getImage: boolean = false;
  isUploadSectionSelected: boolean = true;
  isDigitalSignatureAvailable: boolean;

  drawnSignatureImage: SafeResourceUrl;
  textSignatureImage: SafeResourceUrl;
  identifierValue: string;

  @ViewChild("canvas", { static: true }) canvas: ElementRef;
  signpad: SignaturePad;
  signImage: any;
  signatureText: any;

  selectedOption = "upload";

  signatureStyles = ["Helvetica", "Pacifico", "Arial", "Verdana"];
  selectedSignatureStyle = this.signatureStyles[0];

  userSettings: UserSettings;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  user: User;
  identifier: boolean = false;
  label: String;

  _customSnackbarService: CustomSnackBarService;

  constructor(private route: ActivatedRoute, private _configurationsService: ConfigurationsService, private _changeDetectorRef: ChangeDetectorRef, private router: Router, private _userService: UserService, private changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog, private _settingService: SettingService, private _domSanitizer: DomSanitizer, private _injector: Injector) {
    this._documentDialogService = _injector.get<DocumentDialogService>(DocumentDialogService);
    this._customSnackbarService = _injector.get<CustomSnackBarService>(CustomSnackBarService);
  }

  ngOnInit(): void {
    // this.changeDetectorRef.detectChanges();
    this.getConfigurationSettings();
    this.getUserSettings();
    this.setImageOnNgInit(this.userSettings);
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = user;
      // Mark for check
      this.selectedSignature = this.user.signatureType;
      this.identifier = this.selectedSignature != SignatureType.Token ? true : false;
      if (this.identifier) {
        this.identifierValue = this.user.signatureIdentifier;
      }
      this._changeDetectorRef.markForCheck();
      this.updateLabel();
    });

    // this.signpad = new SignaturePad(this.canvas.nativeElement);

    this._userService.getSignatureImage().subscribe((response) => {
      // console.log(response);
      if (response.imageContent) {
        this.getSignatureImage = this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + response.imageContent);
        // console.log(this.getSignatureImage);

        this.changeDetectorRef.detectChanges();
        this.signatureAdded = false;
        this.uploaded = false;
      } else {
        this.uploaded = true;
        this.signatureAdded = false;
        this.changeDetectorRef.detectChanges();
      }
      this.changeDetectorRef.detectChanges();
    });
    this.selectedSignature = this.user.signatureType;
    this.changeDetectorRef.detectChanges();
    this.showAlert = false;
  }

  getUserSettings() {
    // Subscribe to the setting service

    this._settingService.userSetting$.pipe(takeUntil(this._unsubscribeAll)).subscribe((userSettings: UserSettings) => {
      this.userSettings = userSettings;
    });
  }

  getConfigurationSettings() {
    this._configurationsService.changeConfigurationType().subscribe(
      (response) => {

        this.isDigitalSignatureAvailable = response.digitalSignatureEnabled;

      }
    );
  }


  showSignatureAppearance() {
    this._documentDialogService.openDigitalSignatureDialog().then((response) => {

    });
  }

  createSignature() {
    this._documentDialogService.createSignatureDialog().then((signature) => {
      this.alert.type = "success";
      this.alert.message = "Signature created successfully!";
      this.showAlert = true;
      this.uploaded = true;
      this.signatureAdded = true;
      this.getImage = true;
      this.isUploadSectionSelected = true;
      this.ngOnInit();
      this.getSignatureImage = this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + signature);
      this.changeDetectorRef.detectChanges();
    });
  }


  updateSignature() {
    this._documentDialogService.createSignatureDialog().then((signature) => {
      if (signature != false) {
        this.alert.type = "success";
        this.alert.message = "Signature updated successfully!";
        this.showAlert = true;
        this.signatureAdded = true;
        this.uploaded = true;
        this.changeDetectorRef.detectChanges();
        this.ngOnInit();
      }
    });
  }
  setImageOnNgInit(userSettings: UserSettings) {
    if (userSettings.status == "success") {
      this.getSignatureImage = this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + userSettings.imageContent);
      this.getImage = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  selectDigitalSignature() {
    if (this.selectedSignature == SignatureType.Token) {
      this.identifier = (this.identifier = true) ? false : true;
      let body = {
        signatureType: this.selectedSignature,
      };
      this._userService.changeSignatureType(body).subscribe(
        (response) => {
          console.log(response);
          this._userService.get().subscribe((response) => {
            this.user = response;
            this._customSnackbarService._showSnackbarOnSuccess("Digital ID updated")
          });
        },
        (error) => {
          console.log(error);
          this._customSnackbarService._showSnackbarOnFailure(error.error.errorDescription)
        }
      );
    } else {
      this.identifier = true;
    }

    this.updateLabel();

  }

  updateLabel() {
    switch (this.selectedSignature) {
      case SignatureType.Remote:
        this.label = "enter-your-remote-id";
        break;

      case SignatureType.Server:
        this.label = "enter-your-key";
        break;

      case SignatureType.Token:
        this.label = "";
        break;

      default:
        this.label = "";
    }
  }

  saveIdentifierValue() {
    this.loader = true;
    let body = {
      signatureType: this.selectedSignature,
      signatureIdentifier: this.identifierValue,
    };
    this._userService.changeSignatureType(body).subscribe(
      (response) => {
        this.loader = false;
        this._changeDetectorRef.detectChanges();
        this._userService.get().subscribe((response) => {
          this.user = response;
          this._customSnackbarService._showSnackbarOnSuccess("Digital ID updated")
        });
      },
      (error) => {
        this.loader = false;
        this._changeDetectorRef.detectChanges();
        console.log(error);
        this._customSnackbarService._showSnackbarOnFailure(error.error.errorDescription)
      }
    );
  }
}
