import { ScrollStrategyOptions } from "@angular/cdk/overlay";
import { ChangeDetectorRef, ElementRef, Injectable, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { appConfig } from "app/core/config/app.config.dev";
import { DocumentService } from "app/core/document/document.service";
import { SettingService } from "app/core/setting/setting.service";
import { UserService } from "app/core/user/user.service";
import { SignerService } from "../../signer-service";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
  providedIn: "root",
})
export class CustomSnackBarService {
  // dialogForm: FormGroup;
  constructor(
    public dialog: MatDialog,

    //private dialogRef: MatDialogRef<PrepDocumentComponent>,
    private _translocoService: TranslocoService,
    private _snackBar: MatSnackBar
  ) {}

  public _showSnackbarOnSuccess(content) {
    let sb = this._snackBar.open(this._translocoService.translate(content), "", {
      duration: 2000,
      panelClass: ["success-snackbar"],
      verticalPosition: appConfig.snackVertPos as MatSnackBarVerticalPosition, // "vertical" // Allowed values are  'top' | 'bottom'
      horizontalPosition: appConfig.snackHoriPos as MatSnackBarHorizontalPosition, // "Horizontal"
      // verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
      // horizontalPosition: "left", // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
    // sb.onAction().subscribe(() => {
    //   sb.dismiss();
    // });
  }

  public _showSnackbarOnSuccessWithOutTrans(content) {
    let sb = this._snackBar.open(content, "", {
      duration: 2000,
      panelClass: ["success-snackbar"],
      verticalPosition: appConfig.snackVertPos as MatSnackBarVerticalPosition, // "vertical" // Allowed values are  'top' | 'bottom'
      horizontalPosition: appConfig.snackHoriPos as MatSnackBarHorizontalPosition, // "Horizontal"
      // verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
      // horizontalPosition: "left", // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
    // sb.onAction().subscribe(() => {
    //   sb.dismiss();
    // });
  }

  public _showSnackbarOnFailure(content) {
    let sb = this._snackBar.open(this._translocoService.translate(content), "", {
      duration: 2000,
      panelClass: ["error-snackbar"],
      verticalPosition: appConfig.snackVertPos as MatSnackBarVerticalPosition, // "vertical" // Allowed values are  'top' | 'bottom'
      horizontalPosition: appConfig.snackHoriPos as MatSnackBarHorizontalPosition, // "Horizontal"
      // verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
      // horizontalPosition: "left", // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
    // sb.onAction().subscribe(() => {
    //   sb.dismiss();
    // });
  }

  public _showSnackbarOnCookiesFailure(content, callback?: () => void) {
    let sb = this._snackBar.open(this._translocoService.translate(content), "", {
      duration: 2000,
      panelClass: ["error-snackbar"],
      verticalPosition: appConfig.snackVertPos as MatSnackBarVerticalPosition,
      horizontalPosition: appConfig.snackHoriPos as MatSnackBarHorizontalPosition,
    });
  
    sb.afterDismissed().subscribe(() => {
      if (callback) {
        callback();
      }
    });
  }
  

  public _showSnackbarOnFailureWithOutTrans(content) {
    let sb = this._snackBar.open(content, "", {
      duration: 2000,
      panelClass: ["error-snackbar"],
      verticalPosition: appConfig.snackVertPos as MatSnackBarVerticalPosition, // "vertical" // Allowed values are  'top' | 'bottom'
      horizontalPosition: appConfig.snackHoriPos as MatSnackBarHorizontalPosition, // "Horizontal"
      // verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
      // horizontalPosition: "left", // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
    // sb.onAction().subscribe(() => {
    //   sb.dismiss();
    // });
  }
}
