import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { DocumentService } from "app/core/document/document.service";
import { DeclineDocument } from "app/core/document/document.types";
import { UserService } from "app/core/user/user.service";
import { SettingsSignatureComponent } from "app/modules/settings/signature/signature.component";
import { DeclineFormComponent } from "../../doc-decline/decline-form/decline-form.component";
import { ManageSignatureDialogComponent } from "../signature-dialog/manage-signature-dialog/manage-signature-dialog.component";
import { fromEvent } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { PopupComponent } from "app/modules/popup/popup.component";
import { SignatureTypesDialogComponent } from "../signature-dialog/signature-types-dialog/signature-types-dialog.component";
import { SignatureAppearanceComponent } from "app/modules/settings/signature-appearance/signature-appearance.component";

@Injectable({
  providedIn: "root",
})
export class DocumentDialogService {
  dialogForm: FormGroup;
  selectedOption: string;
  public signatureImage: any;
  private templateName: string = '';
  private templateData: { name: string; description: string };
  constructor(private _documentService: DocumentService, private _activatedRoute: ActivatedRoute, private _router: Router, public dialog: MatDialog, private _userService: UserService, private _formBuilder: FormBuilder, private _fuseConfirmationService: FuseConfirmationService, private _translocoService: TranslocoService) { }

  public get signature(): string {
    return this.signatureImage;
  }

  async openDigitalSignatureDialog(): Promise<any> {
    const dialogRef = this.dialog.open(SignatureAppearanceComponent, {
      width: "450px",
      height: "auto",
      // maxHeight: "900px",
      disableClose: true,
    });

    const result = await dialogRef.afterClosed().toPromise();

    if (result === 'confirm') {
      await this._userService.getSignatureImage().toPromise()
        .then((response) => {
          this.signatureImage = response.imageContent;
          return response.imageContent;
        })
        .catch((error) => {
          console.error('Error fetching signature image: ' + error);
        });
    } else {
      return false;
    }

    // return dialogRef
    //   .afterClosed()
    //   .toPromise()
    //   .then(async (result) => {
    //     console.log(result);

    //     await this._userService
    //       .getSignatureImage()
    //       .toPromise()
    //       .then(async (response) => {
    //         this.signatureImage = response.imageContent;
    //         return await Promise.resolve(response.imageContent);
    //       });
    //   });
  }

  async createSignatureDialog(): Promise<any> {
    const dialogRef = this.dialog.open(ManageSignatureDialogComponent, {
      width: "550px",
      height: this.selectedOption === "type" ? "800px" : "490",
      // maxHeight: "900px",
      disableClose: true,
    });

    const result = await dialogRef.afterClosed().toPromise();

    if (result === 'confirm') {
      await this._userService.getSignatureImage().toPromise()
        .then((response) => {
          this.signatureImage = response.imageContent;
          return response.imageContent;
        })
        .catch((error) => {
          console.error('Error fetching signature image: ' + error);
        });
    } else {
      return false;
    }

    // return dialogRef
    //   .afterClosed()
    //   .toPromise()
    //   .then(async (result) => {
    //     console.log(result);

    //     await this._userService
    //       .getSignatureImage()
    //       .toPromise()
    //       .then(async (response) => {
    //         this.signatureImage = response.imageContent;
    //         return await Promise.resolve(response.imageContent);
    //       });
    //   });
  }
  async signatureTypesDialog(ownerName, keyList: any[]): Promise<any> {
    const dialogConfig = new MatDialogConfig();
    if (keyList.length == 0) {
      dialogConfig.data = {
        name: ownerName,
        signatureTypeAddedOrNot: true,
      };
    } else if (keyList.length > 0) {
      dialogConfig.data = {
        name: ownerName,
        key: keyList,
        signatureTypeAddedOrNot: false,
      };
    }
    console.log(dialogConfig.data);

    const dialogRef = this.dialog.open(SignatureTypesDialogComponent, {
      width: "550px",
      height: this.selectedOption === "type" ? "800px" : "490",
      // maxHeight: "900px",
      autoFocus: false,
      data: dialogConfig.data,
      // disableClose: !dialogConfig.data.dismissible,
      restoreFocus: false,
      // disableClose: true,
    });

    dialogRef;
  }

  async uploadSignatureDialog(): Promise<any> {
    const dialogRef = this.dialog.open(SettingsSignatureComponent, {
      width: "auto",
      maxHeight: "90vh",
      disableClose: true,
    });

    return dialogRef
      .afterClosed()
      .toPromise()
      .then(async (result) => {
        await this._userService
          .getSignatureImage()
          .toPromise()
          .then(async (response) => {
            // console.log(response);
            // this.userSettings.imageContent = response.imageContent; //this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64,"+response.imageContent);
            // this.getSignatureImage = response.imageContent; //this._domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64,"+response.imageContent);
            // this.changeDetectorRef.detectChanges();
            this.signatureImage = response.imageContent;
            // console.log("image uploaded !!");
            // console.log(response.imageContent);

            return await Promise.resolve(response.imageContent);
          });
      });
  }

  /**
   * Opens component in a dialog
   * to get value from Form inside the component
   */
  decline(_docDetails: any, user: any) {
    const dialogRef = this.dialog.open(DeclineFormComponent, {
      width: "auto",
      maxHeight: "90vh",
      minWidth: "100vh",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);
      // console.log("docId", this._docDetails.id);

      //implement decline end point hit
      let declineDoc: DeclineDocument = {
        docId: Number(_docDetails.id),
        userId: Number(user.id),
        declined: true,
        reason: result.message,
      };
      if (result.message != "") {
        this._documentService.declineDocument(declineDoc).subscribe((response) => {
          // console.log(response);
          this._router.navigate(["/void"]);
        });
      }
      // console.log(declineDoc);
    });
  }

  openDialogForm(status, message): void {
    this.dialogForm = this._formBuilder.group({
      title: status === "error" ? "Error" : this._translocoService.translate("all-done"),
      message: '<span class="font-medium">' + this._translocoService.translate(message) + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: "OK",
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: true,
          label: "No",
        }),
        confirm: this._formBuilder.group({
          show: true,
          color: "primary",
          label: this._translocoService.translate("Yes"),
        }),
      }),
    });

    const dialogRef = this._fuseConfirmationService.open(this.dialogForm.value);
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "confirmed") {
        let redirectURL = "/signed-in-redirect";
        this._router.navigateByUrl(redirectURL);
      }
    });
  }

  openDialogFormNotOnline(status, message): void {
    this.dialogForm = this._formBuilder.group({
      title: status === "error" ? "Error" : "Cannot view Document!",
      message: '<span class="font-medium">' + message + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: "OK",
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: false,
          label: "No",
        }),
        confirm: this._formBuilder.group({
          show: true,
          color: "primary",
          label: "OK",
        }),
      }),
      // dismissible: true
    });

    const dialogRef = this._fuseConfirmationService.open(this.dialogForm.value);
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "confirmed") {
        let redirectURL = "/signed-in-redirect";
        this._router.navigateByUrl(redirectURL);
      }
      //this.dialogRef.close();
    });
  }

  openDialogFormDocOwner(status, message): void {
    this.dialogForm = this._formBuilder.group({
      title: status === "Document is Void!" ? this._translocoService.translate("Document is Void!") : this._translocoService.translate("all-done"),
      message: '<span class="font-medium">' + this._translocoService.translate(message) + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: this._translocoService.translate("Document list"),
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: true,
          label: this._translocoService.translate("view"),
        }),
        confirm: this._formBuilder.group({
          show: true,
          color: "primary",
          label: this._translocoService.translate("documents"),
          // label: "Document list",
        }),
      }),
      // dismissible: true
    });

    const dialogRef = this._fuseConfirmationService.open(this.dialogForm.value);
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "confirmed") {
        let redirectURL = "/signed-in-redirect";
        this._router.navigateByUrl(redirectURL);
      } else {
        window.location.reload();
      }
      // this.dialogRef.close();
    });
  }

  enterPrizeDialog(status, message) {
    this.dialogForm = this._formBuilder.group({
      title: this._translocoService.translate(status),
      message: '<span class="font-medium">' + this._translocoService.translate(message) + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: this._translocoService.translate("Document list"),
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: true,
          label: this._translocoService.translate("not-now"),
        }),
        confirm: this._formBuilder.group({
          show: true,
          color: "primary",
          label: this._translocoService.translate("okay"),
          // label: "Document list",
        }),
      }),
      // dismissible: true
    });

    const dialogRef = this._fuseConfirmationService.open(this.dialogForm.value);
    return dialogRef;
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result == "confirmed") {
    //     let redirectURL = "/signed-in-redirect";
    //     this._router.navigateByUrl(redirectURL);
    //   } else {
    //     window.location.reload();
    //   }
    //   // this.dialogRef.close();
    // });
  }


  async voidDialog(status, message) {
    this.dialogForm = this._formBuilder.group({
      title: this._translocoService.translate(status),
      message: '<span class="font-medium">' + this._translocoService.translate(message) + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: this._translocoService.translate("Document list"),
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: true,
          label: this._translocoService.translate("cancel"),
        }),
        confirm: this._formBuilder.group({
          show: true,
          color: "primary",
          label: this._translocoService.translate("okay"),
          // label: "Document list",
        }),
      }),
      // dismissible: true
    });

    const dialogRef = await this._fuseConfirmationService.open(this.dialogForm.value);
    return dialogRef;
  }

  openDialogOnSendMailDocOwner(status, message): void {
    this.dialogForm = this._formBuilder.group({
      title: status === "Document is Void!" ? this._translocoService.translate("Document is Void!") : this._translocoService.translate("all-done"),
      message: '<span class="font-medium">' + this._translocoService.translate(message) + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: this._translocoService.translate("Document list"),
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: false,
          label: "OK",
        }),
        confirm: this._formBuilder.group({
          show: true,
          color: "primary",
          label: this._translocoService.translate("documents"),
          // label: "Document list",
        }),
      }),
      // dismissible: true
    });

    const dialogRef = this._fuseConfirmationService.open(this.dialogForm.value);
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "confirmed") {
        let redirectURL = "/signed-in-redirect";
        this._router.navigateByUrl(redirectURL);
      }
      // this.dialogRef.close();
    });
  }

  openDialogFormMail(status, message): void {
    let dialogtitle = "";
    if (status == "Fill all Fields!") {
      dialogtitle = this._translocoService.translate("fill-all-fields");
    } else if (status == "Wait!") {
      dialogtitle = this._translocoService.translate("wait");
    } else if (status == "Cannot Finish Document!") {
      dialogtitle = this._translocoService.translate("cannot-finish-document");
    } else {
      dialogtitle = this._translocoService.translate("all-done");
    }
    this.dialogForm = this._formBuilder.group({
      title: this._translocoService.translate(dialogtitle), //status === "Fill all Fields!" ? "Fill all Fields!" : "All done!",
      message: '<span class="font-medium">' + this._translocoService.translate(message) + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: "OK",
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: true,
          label: "OK",
        }),
        confirm: this._formBuilder.group({
          show: false,
          color: "primary",
          label: this._translocoService.translate("documents"),
          // label: "Document list",
        }),
      }),
      // dismissible: true
    });

    const dialogRef = this._fuseConfirmationService.open(this.dialogForm.value);
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "confirmed") {
        // let redirectURL = "/signed-in-redirect";
        // this._router.navigateByUrl(redirectURL);
      }
      // this.dialogRef.close();
    });
  }

  openDialogFormSigner(status, message, _documentId, accountStatus: string = ""): void {
    this.dialogForm = this._formBuilder.group({
      title: status === "Document is Void!" ? this._translocoService.translate("Document is Void!") : this._translocoService.translate("all-done"),
      message: '<span class="font-medium">' + this._translocoService.translate(message) + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: this._translocoService.translate("close"),
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: true,
          label: this._translocoService.translate("view"),
        }),
        confirm: this._formBuilder.group({
          show: true,
          color: "primary",
          label: this._translocoService.translate("close"),
        }),
      }),
      // dismissible: true
    });

    const dialogRef = this._fuseConfirmationService.open(this.dialogForm.value);
    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);

      if (result == "confirmed" && accountStatus == "GUEST") {
        let redirectURL = "/get-feedback";

        this._router.navigate([redirectURL], {
          relativeTo: this._activatedRoute,
          queryParams: {
            dI: btoa(_documentId),
          },
          queryParamsHandling: "merge",
          // preserve the existing query params in the route
          skipLocationChange: false,
          // do not trigger navigation
        });
      } else if (result == "cancelled") {
        window.location.reload();
      } else {
        let redirectURL = "/signed-in-redirect";
        this._router.navigateByUrl(redirectURL);
      }
      // this.dialogRef.close();
    });
  }

  _documentId(_documentId: any) {
    throw new Error("Method not implemented.");
  }


  setTemplateName(name: string): void {
    console.log('Setting document name:' + name);
    this.templateName = name;
  }

  getTemplateName(): string {
    return this.templateName;;
  }

  setTemplateData(name: string, description: string) {
    this.templateData = { name, description };
  }

  getTemplateData() {
    return this.templateData;
  }



}


