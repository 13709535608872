import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, RouterModule } from "@angular/router";
import { FuseModule } from "@fuse";
import { FuseMockApiModule } from "@fuse/lib/mock-api";
import { FuseConfigModule } from "@fuse/services/config";
import { CoreModule } from "app/core/core.module";
import { MarkdownModule } from "ngx-markdown";
// import { appConfig } from 'app/core/config/app.config';
import { appConfig } from "./core/config/app.config.dev";
// import { appConfig } from './core/config/app.config.prod';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { MatButtonModule } from "@angular/material/button";
import { LayoutModule } from "app/layout/layout.module";
import { mockApiServices } from "app/mock-api";
import { TranslocoCoreModule } from "./core/transloco/transloco.module";
import { PopupComponent } from "./modules/popup/popup.component";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { AnalyticsService } from "./analytics.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ManageCookiesDialogComponent } from "./manage-cookies-dialog/manage-cookies-dialog.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FuseScrollbarModule } from "@fuse/directives/scrollbar";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: "enabled",
};

@NgModule({
  declarations: [AppComponent, PopupComponent, ManageCookiesDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Core module of your application
    CoreModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    FuseScrollbarModule,
    MatSlideToggleModule,

    TranslocoModule,
    MatAutocompleteModule,

    // Layout module of your application
    LayoutModule,

    TranslocoCoreModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),
  ],
  entryComponents: [PopupComponent],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    TranslocoService,
    AnalyticsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
