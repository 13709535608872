import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { InitialDataResolver } from "app/app.resolvers";

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: "", pathMatch: "full", redirectTo: "documents" },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  //{path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'documents'},
  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "documents" },
  // Auth routes for guests
  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      { path: "confirm-account", loadChildren: () => import("app/modules/auth/confirm-account/confirm-account.module").then((m) => m.ConfirmAccountModule) },
      { path: "confirmation-required", loadChildren: () => import("app/modules/auth/confirmation-required/confirmation-required.module").then((m) => m.AuthConfirmationRequiredModule) },
      { path: "forgot-password", loadChildren: () => import("app/modules/auth/forgot-password/forgot-password.module").then((m) => m.AuthForgotPasswordModule) },
      { path: "reset-password", loadChildren: () => import("app/modules/auth/reset-password/reset-password.module").then((m) => m.AuthResetPasswordModule) },
      { path: "sign-in", loadChildren: () => import("app/modules/auth/sign-in/sign-in.module").then((m) => m.AuthSignInModule) },
      { path: "sign-up", loadChildren: () => import("app/modules/auth/sign-up/sign-up.module").then((m) => m.AuthSignUpModule) },
      { path: "verified-user", loadChildren: () => import("app/modules/auth/verified-user/verified-user.module").then((m) => m.VerifiedUserModule) },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      { path: "sign-out", loadChildren: () => import("app/modules/auth/sign-out/sign-out.module").then((m) => m.AuthSignOutModule) },
      { path: "unlock-session", loadChildren: () => import("app/modules/auth/unlock-session/unlock-session.module").then((m) => m.AuthUnlockSessionModule) },
    ],
  },

  // Landing routes
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [{ path: "home", loadChildren: () => import("app/modules/landing/home/home.module").then((m) => m.LandingHomeModule) }],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [{ path: "example", loadChildren: () => import("app/modules/admin/example/example.module").then((m) => m.ExampleModule) }],
  },

  // Doc routes
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children: [
      { path: "documents", loadChildren: () => import("app/modules/document/document.module").then((m) => m.DocumentModule) },
      { path: "sentDocuments", loadChildren: () => import("app/modules/document/sent-document/sent-document.module").then((m) => m.SentDocumentModule) },
      { path: "draftDocuments", loadChildren: () => import("app/modules/document/draft-document/draft-document.module").then((m) => m.DraftDocumentModule) },
      { path: "pendingDocuments", loadChildren: () => import("app/modules/document/pending-document/pending-document.module").then((m) => m.PendingDocumentModule) },
      { path: "signedDocuments", loadChildren: () => import("app/modules/document/signed-document/signed-document.module").then((m) => m.SignedDocumentModule) },
      { path: "completeDocuments", loadChildren: () => import("app/modules/document/complete-document/complete-document.module").then((m) => m.CompleteDocumentModule) },
      { path: "voidDocuments", loadChildren: () => import("app/modules/document/void-document/void-document.module").then((m) => m.VoidDocumentModule) },
      { path: "template-documents", loadChildren: () => import("app/modules/document/template-document/template-document.module").then((m) => m.TemplateDocumentModule) },
     { path: "superAdminSettings", loadChildren: () => import("app/modules/superAdminSettings/superAdministrationSettings.module").then((m) => m.SuperAdministrationSettingsModule) },
      { path: "super-admin-panel", loadChildren: () => import("app/modules/superAdminSettings/super-admin-panel/super-admin-panel.module").then((m) => m.SuperAdminPanelModule) },
    ],
  },
  
  // Super Admin Panel
  {
    path: "",
 //   path: "superAdminSettings", Not working 

   // superAdminSettings
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    
  },
  // Settings
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children: [
      { path: "settings", loadChildren: () => import("app/modules/settings/settings.module").then((m) => m.SettingsModule) },
      { path: "adminsettings", loadChildren: () => import("app/modules/administrationSettings/administrationSettings.module").then((m) => m.AdministrationSettingsModule) },
    ],
  },


  // Wait Page
  {
    path: "",

    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    // resolve    : {
    //     initialData: InitialDataResolver,
    // },
    children: [{ path: "wait", loadChildren: () => import("app/modules/document/wait-document/wait-document.module").then((m) => m.WaitDocumentModule) }],
  },

  // Star Rating
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [{ path: "feedback", loadChildren: () => import("app/modules/starRating/star-rating.module").then((m) => m.StarRatingModule) }],
  },

  // Link Expired
  {
    path: "",
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    // resolve    : {
    //     initialData: InitialDataResolver,
    // },
    children: [{ path: "link-expired", loadChildren: () => import("app/modules/errorPages/link-expired/link-expired.module").then((m) => m.LinkExpiredModule) }],
  },

  // DocDeclined
  {
    path: "",
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [{ path: "void", loadChildren: () => import("app/modules/errorPages/decline-screen/decline-screen.module").then((m) => m.DeclineScreenModule) }],
  },

  { path: "get-feedback", pathMatch: "full", redirectTo: "feedback" },
];
