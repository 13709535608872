import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FilterByStatusService } from './modules/document/filter-by-status.service';
import { TranslocoService } from '@ngneat/transloco';
import { AnalyticsService } from './analytics.service';
import { DocumentDialogService } from './modules/document/document-common/document-dialogs/document-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { TemplateDialogsComponent } from './modules/document/document-common/template-dialogs/template-dialogs.component';
import { ManageCookiesDialogComponent } from './manage-cookies-dialog/manage-cookies-dialog.component';
import { CookieService } from './core/cookie/cookie.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    cookieMessage: string = this._translocoService.translate("this-website-uses-cookies-to-ensure-you-get-the-best-experience-on-our-website");
    cookieLinkText: string = this._translocoService.translate("learn-more-about-cookies");

    private backdrop: any;
    selectedOption: string;

    /**
     * Constructor
     */
    constructor(private titleService: Title,
        private statusService: FilterByStatusService,
        private _translocoService: TranslocoService,
        private el: ElementRef, private renderer: Renderer2,
        private _gaService: AnalyticsService,
        public dialog: MatDialog,
        private cookieService: CookieService,
        private analyticServce: AnalyticsService,
        private _documentDialogService: DocumentDialogService,
    ) {
        this.setTitle()
    }
    ngOnInit(): void {

        this._gaService.init();

        this.backdrop = this.renderer.createElement('div');
        this.renderer.addClass(this.backdrop, 'cookie-consent-backdrop');
        this.renderer.appendChild(document.body, this.backdrop);

        // Apply styles to the backdrop
        this.renderer.setStyle(this.backdrop, 'position', 'fixed');
        this.renderer.setStyle(this.backdrop, 'top', '0');
        this.renderer.setStyle(this.backdrop, 'left', '0');
        this.renderer.setStyle(this.backdrop, 'width', '100%');
        this.renderer.setStyle(this.backdrop, 'height', '100%');
        this.renderer.setStyle(this.backdrop, 'background', 'rgba(0, 0, 0, 0.5)');
        this.renderer.setStyle(this.backdrop, 'backdrop-filter', 'blur(3px)');

        this.renderer.setProperty(this.el.nativeElement, 'style', 'pointer-events: none;');

        let cc = window as any;
        cc.cookieconsent.initialise({
            palette: {
                popup: {
                    background: "#1e293b",
                },
                button: {
                    background: "#ffe000",
                    text: "#164969"
                }
            },
            theme: "classic",
            content: {
                message: this.cookieMessage,
                dismiss: this._translocoService.translate("deny"),
                allow: this._translocoService.translate("allow-cookies"),
                link: this.cookieLinkText,
                href: "https://www.cloudflare.com/learning/privacy/what-are-cookies/"
            },
            type: "opt-in",
            onInitialise: () => {
                this.handleConsentChange();
            },
            onStatusChange: (status) => {

                console.log(status);
                
                if (status === 'allow') {
                    this.cookieService.setCookie('isRejectedCookie', 'false', 7);
                } else if (status === 'deny') {
                    this.openDenyDialog();
                }

                this.handleConsentChange();
            },
            onPopupClose: () => {
                const cookiePolicyButton = document.querySelector('.cc-revoke');
                if (cookiePolicyButton) {
                    cookiePolicyButton.remove();
                }
            }
        });


    }

    private handleConsentChange() {

        const performanceCookiesEnabled = this.cookieService.getCookie('PerformanceCookiesEnabled') === 'true';

        this.analyticServce.updatePerformanceCookiesStatus(performanceCookiesEnabled);

        this.renderer.setProperty(this.el.nativeElement, 'style', 'pointer-events: auto;');
        this.renderer.removeChild(document.body, this.backdrop);
    }

    private openDenyDialog(): void {
        const dialogRef = this.dialog.open(ManageCookiesDialogComponent, {
          width: "650px",
          height: "490",
          autoFocus: false,
          restoreFocus: false,
          disableClose: true,
        });
      }

    public setTitle() {
        let lang = localStorage.getItem('lang')
        if (lang == 'en' || lang === null) {
            this.titleService.setTitle('vScrawl: #1 eSigning and CLM solution');
        }
        else if (lang == 'es') {
            this.titleService.setTitle("vScrawl: Solución n.º 1 de firma electrónica y CLM");
        }

    }
}
