<div class="w-full   ...">
    <div>
        <div class="flex">
            <div class="text-2xl font-semibold  pt-4 pb-4  ">
                {{"decline-to-sign" | transloco}} </div>
            <div class="ml-auto" (click)="close()">
                <mat-icon class=" w-4" [svgIcon]="'heroicons_outline:x'" matSuffix>
                </mat-icon>
            </div>
        </div>
        <div>

            <div [formGroup]="formGroup">
                <div class="text-md font-medium  pt-2 pb-4  ">
                    {{"please-provide-a-reason-for-declining" | transloco}}
                    <!-- <mat-form-field class="w-full">
                        <input matInput placeholder="Enter your email" [formControl]="emailField" required>
                        <mat-error *ngIf="emailField.invalid">{{ getErrorMessage(emailField) }}</mat-error>
                    </mat-form-field> -->
                </div>
                <div>
                    <mat-form-field class="w-full">
                        <textarea matInput placeholder="{{'enter-your-reason' | transloco}}" [formControl]="feedbackField"
                            required></textarea>
                        <mat-error *ngIf="feedbackField.invalid">{{ getErrorMessage(feedbackField) }}</mat-error>
                    </mat-form-field>
                </div>


            </div>
            <div class="flex">
                <div class="flex items-end pl-2 ml-auto space-x-0.5 sm:space-x-2">


                    <div class="">
                        <button class="ml-2" mat-raised-button color="primary" (click)="onSubmit()"
                            [disabled]="formGroup.invalid">
                            <!-- Decline to Sign -->{{"decline-to-sign" | transloco}}
                        </button>
                    </div>


                    <div class="">
                        <button mat-raised-button (click)="close()">
                            <!-- Cancel -->{{"cancel" | transloco}}
                        </button>
                    </div>


                </div>

            </div>
            <!-- <div class="flex">
                <div class="flex-col ml-auto">
                    <div class="ml-auto">
                        <button class="ml-2" mat-raised-button color="primary" (click)="onSubmit()"
                            [disabled]="formGroup.invalid">
                           {{"decline-to-sign" | transloco}}
                        </button>
                        <button mat-raised-button (click)="close()">
                            {{"cancel" | transloco}}
                        </button>
                    </div>

                </div>

            </div> -->

        </div>


    </div>



</div>