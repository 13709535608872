import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { BooleanInput } from "@angular/cdk/coercion";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AccountType, Role, User } from "app/core/user/user.types";
import { UserService } from "app/core/user/user.service";

@Component({
  selector: "user",
  templateUrl: "./user.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "user",
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */
  // isAdmin: boolean = false;

  @Input() showAvatar: boolean = true;
  @Input() isAdmin: boolean = false;
  isPartInOrganization: boolean = false;
  user: User;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _router: Router, private _userService: UserService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  ngOnInit(): void {
    // console.log("value coming from parent = " + this.isAdmin);
    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = user;

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
    if (this.user.accountType == AccountType.Organization) {
      this.isAdmin = true;
    }
    console.log("organization: ", this.user.organization);

    if (this.user.organization != null) {
      this.isPartInOrganization = true
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    this._userService
      .update({
        ...this.user,
        status,
      })
      .subscribe();
  }

  hasSuperAdminRole(): boolean {
    return this.user?.roles?.some(role => role.name === 'ROLE_SUPER_ADMIN');
  }


  /**
   * Sign out
   */
  signOut(): void {
    localStorage.clear();

    this._router.navigate(["/sign-out"]);
  }

  /**
   * Settings
   */
  toSettings(): void {
    this._router.navigate(["/settings"]);
  }

  toAdminSettings(): void {
    this._router.navigate(["/adminsettings"]);
  }

  toTemplatDocuments(): void {
    this._router.navigate(["/template-documents"]);
  }
}
