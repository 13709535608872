/* tslint:disable:max-line-length */
import { FuseNavigationItem } from "@fuse/components/navigation";
export const defaultNavigation: FuseNavigationItem[] = [

  {

    id: "dashboard",
    title: "dashboard",
    type: "group",
    // meta: "simple_user",
    children: [
      {
        id: "documents",
        title: "Documents",
        type: "collapsable",
        meta: "simple_user",
        icon: "heroicons_outline:document-text",
        children: [
          {
            id: "docs",
            title: "all-documents",
            type: "basic",
            icon: "feather:inbox",
            link: "/documents",
          },
          {
            id: "pages.coming-soon.classic",
            title: "Draft",
            type: "basic",
            icon: "heroicons_outline:document",
            link: "/draftDocuments",
          },
          {
            id: "pages.coming-soon.classic",
            title: "Pending",
            type: "basic",
            icon: "heroicons_outline:clock",
            link: "/pendingDocuments",
          },
          {
            id: "pages.coming-soon.classic",
            title: "Sent",
            icon: "feather:send",
            type: "basic",
            link: "/sentDocuments",
          },
          {
            id: "pages.coming-soon.classic",
            title: "Signed",
            icon: "heroicons_outline:pencil-square",
            type: "basic",
            link: "/signedDocuments",
          },
          {
            id: "pages.coming-soon.classic",
            title: "Completed",
            icon: "feather:check-circle",
            type: "basic",
            link: "/completeDocuments",
          },
          {
            id: "pages.coming-soon.classic",
            title: "Void",
            icon: "feather:x-circle",
            type: "basic",
            link: "/voidDocuments",
          },
        ],
      },
      {
        id: "conf",
        title: "Configurations",
        type: "basic",
        icon: "feather:settings",
        link: "/superAdminSettings/configurations",
        meta: "super_admin",
      },
      {
        id: "users",
        title: "Users",
        type: "basic",
        icon: "feather:users",
        link: "/superAdminSettings/users",
        meta: "super_admin",

      },
      {
        id: "org",
        title: "Organization",
        type: "basic",
        icon: "feather:codesandbox",
        link: "/superAdminSettings/organization",
        meta: "super_admin",

      },
      {
        id: "service-plane",
        title: "Service Plan",
        type: "basic",
        icon: "feather:hexagon",
        link: "/superAdminSettings/serviceplan",
        meta: "super_admin",

      },
      {
        id: "system",
        title: "System",
        type: "collapsable",
        meta: "super_admin",
        icon: "settings_overscan",
        // icon: "heroicons_outline:document",
        children: [
          {
            id: "settings",
            title: "Settings",
            type: "basic",
            icon: "mat_outline:settings_suggest",
            link: "superAdminSettings/settings",
          },
          {
            id: "jwt",
            title: "JWT",
            icon: "heroicons_outline:code-bracket",
            type: "basic",
            link: "superAdminSettings/jwt",
          },
          {
            id: "filesystem",
            title: "FileSystem",
            type: "basic",
            icon: "heroicons_outline:folder",
            link: "superAdminSettings/filesystem",
          },
          {
            id: "evidences",
            title: "Evidences",
            type: "basic",
            icon: "heroicons_outline:document-check",
            link: "superAdminSettings/evidences",
          },
        ]
      },
      {
        id: "externalservices",
        title: "External Services",
        type: "collapsable",
        icon: "border_outer",
        meta: "super_admin",
        children: [
          {
            id: "smtp",
            title: "SMTP",
            type: "basic",
            icon: "heroicons_outline:document-magnifying-glass",
            link: "superAdminSettings/smtp",
          },
          {
            id: "sms",
            title: "SMS",
            type: "basic",
            icon: "heroicons_outline:chat-bubble-left",
            link: "superAdminSettings/sms",
          },
          {
            id: "signingmiddleware",
            title: "Signing Middleware",
            type: "basic",
            icon: "heroicons_outline:arrows-pointing-in",
            link: "superAdminSettings/signing-middleware",
          },
          {
            id: "clientsigning",
            title: "Client Signing",
            type: "basic",
            icon: "heroicons_outline:clipboard-document",
            link: "superAdminSettings/client-signing",
          },
          {
            id: "cscserver",
            title: "CSC Server",
            type: "basic",
            icon: "heroicons_outline:server-stack",
            link: "superAdminSettings/csc-server",
          },
        ]
      },
    ]
  },



];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: "example",
    title: "Example",
    type: "basic",
    icon: "heroicons_outline:chart-pie",
    link: "/example",
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: "example",
    title: "Example",
    type: "basic",
    icon: "heroicons_outline:chart-pie",
    link: "/example",
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: "example",
    title: "Example",
    type: "basic",
    icon: "heroicons_outline:chart-pie",
    link: "/example",
  },
];
