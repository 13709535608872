import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { take } from "rxjs/operators";
import { AvailableLangs, TranslocoService } from "@ngneat/transloco";
import { DomSanitizer, SafeHtml, Title } from "@angular/platform-browser";
import {
  FuseNavigationItem,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from "@fuse/components/navigation";
import { Router } from "@angular/router";
import { ReplaySubject, Subject } from "rxjs";
import { FuseUtilsService } from "@fuse/services/utils/utils.service";
import { DocumentService } from "app/core/document/document.service";
import { SignerDetails } from "app/core/document/document.types";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SettingsSignatureComponent } from "app/modules/settings/signature/signature.component";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "decline-form",
  templateUrl: "./decline-form.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "decline-form",
})
export class DeclineFormComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private _documentService: DocumentService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DeclineFormComponent>,
    private titleService: Title,
    private _translocoService: TranslocoService,
    private _router: Router,
    private _elementRef: ElementRef,
    private _fuseUtilsService: FuseUtilsService,
    private sanitizer: DomSanitizer
  ) {
    this.formGroup = new FormGroup({
      feedbackField: new FormControl("", [
        Validators.required,
        Validators.minLength(25),
        Validators.maxLength(3000),
      ]),
    });
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.formGroup = new FormGroup({
      feedbackField: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(3000),
      ]),
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  close() {
    this.dialogRef.close({ message: "" });
    // this.dialog.closeAll();
  }

  getErrorMessage(control: AbstractControl): string {
    // Don't say anything if control doesn't exist, or is valid
    if (!control || control.valid) {
      return "";
    }

    // Required always comes first
    if (control.hasError("required")) {
      return "Cannot be empty";
    }
    // if (control.hasError("email")) {
    //   return "Must be a valid email";
    // }
    if (control.hasError("minlength")) {
      const limit = control.getError("minlength").requiredLength;
      return `Must be at least ${limit} characters`;
    }
    if (control.hasError("minlength")) {
      const limit = control.getError("maxlength").requiredLength;
      return `Must be no more than ${limit} characters`;
    }

    // Default general error message
    return "Invalid input";
  }

  onSubmit() {
    // this.formGroup.reset();
    this.dialogRef.close({ message: this.feedbackField.value });
  }


  get feedbackField(): AbstractControl {
    return this.formGroup.get("feedbackField");
  }
}
